import { Component } from '@angular/core';
import { AppConfigService } from '@incepto/core';

@Component({
  selector: 'incepto-gateway-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent {
  siteName = '';

  constructor(private appConfigService: AppConfigService) {
    this.siteName = this.appConfigService.getAsString('DD_HOSTNAME') ?? ' ';
  }
}
