import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceMonitorsComponent } from './stored-resource-monitors-component/resource-monitors.component';
import { ResourceMonitorsDataAccessModule } from '@incepto-gateway/resource-monitors-data-access';
// eslint-disable-next-line max-len -- line is well formatted
import { ResourceMonitorsDetailsComponent } from './stored-resource-monitors-component/resource-monitors-details/resource-monitors.details.component';

@NgModule({
  imports: [CommonModule, ResourceMonitorsDataAccessModule],
  declarations: [ResourceMonitorsComponent, ResourceMonitorsDetailsComponent],
  exports: [ResourceMonitorsComponent, ResourceMonitorsDetailsComponent],
})
export class ResourceMonitorsViewsModule {}
