import { Component, OnInit } from '@angular/core';
import { SharedFacade } from '@incepto-gateway/shared/state';
import { UserSessionRepositoryService } from '@incepto/core';

@Component({
  selector: 'incepto-gateway-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public userSessionRepositoryService: UserSessionRepositoryService,
    private sharedFacade: SharedFacade
  ) {}

  ngOnInit(): void {
    this.sharedFacade.loadHomeActionConfig();
  }
}
