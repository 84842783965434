<div class="wrapper d-flex login-container">
  <div class="form-card">
    <h2 class="card-header">
      GATEWAY <br />
      Log in
    </h2>
    <div class="card-container">
      <form
        [formGroup]="form"
        class="d-flex flex-shrink-1 flex-column bg-front"
      >
        <label for="inputUsername">User name</label>
        <input
          type="username"
          id="inputUsername"
          name="username"
          formControlName="username"
          class="form-control im-field mt-2 mb-1"
          placeholder="Enter your user name"
          required
          autofocus
        />
        <label for="inputPassword">Password</label>
        <input
          type="password"
          id="inputPassword"
          name="password"
          formControlName="password"
          class="form-control im-field mt-1 mb-2"
          placeholder="Enter your password"
          required
        />

        <button
          *ngIf="!loading"
          class="u-width-100 signin-btn im-btn"
          (click)="completeLoginStep(getUserInputs())"
        >
          Log in
        </button>
        <div *ngIf="loading" class="d-flex u-width-100 signin-btn im-btn">
          <div class="spinner-container"><div class="spinner"></div></div>
        </div>
      </form>
    </div>
  </div>
</div>
