import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromExamPerUseCase from './state/reducers/exam-per-use-case.reducer';
import { ExamPerUseCaseEffects } from './state/effects/exam-per-use-case.effects';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromExamPerUseCase.EXAMS_PER_USE_CASE_FEATURE_KEY,
      fromExamPerUseCase.examsPerUseCaseReducer
    ),
    EffectsModule.forFeature([ExamPerUseCaseEffects]),
  ],
})
export class ExamPerUseCaseDataAccessModule {}
