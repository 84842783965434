import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IManualLaunchTask } from '../../models/manual-launch-task.model';
import { manualLaunchTaskActions } from '../actions/manual-launch-task.actions.types';

export const MANUAL_LAUNCH_TASKS_FEATURE_KEY = 'manual-launch-tasks';

export type RSQLFilter =
  | RSQLFilterRegexQuery
  | RSQLFilterInQuery
  | RSQLFilterEqualQuery;

type RSQLFilterRegexQuery = {
  key: string;
  value: string;
  operator: '=rei=';
};

type RSQLFilterInQuery = {
  key: string;
  value: string[];
  operator: '=in=';
};

type RSQLFilterEqualQuery = {
  key: string;
  value: string;
  operator: '==';
};

export interface ManualLaunchTaskState extends EntityState<IManualLaunchTask> {
  manualLaunchLoaded: boolean;
  selectedManualLaunchTask?: string | number;
  error?: string | null;
  page: number;
  enableNextPage: boolean;
  processingRequestIdFilter: RSQLFilterRegexQuery;
  patientIDFilter: RSQLFilterRegexQuery;
  accessionNumberFilter: RSQLFilterRegexQuery;
  useCaseFilter: RSQLFilterInQuery;
  statusFilter: RSQLFilterInQuery;
  typeFilter: RSQLFilterInQuery;
  originFilter: RSQLFilterInQuery;
  csvFilter: RSQLFilterRegexQuery;
}

export const manualLaunchTaskAdapter: EntityAdapter<IManualLaunchTask> =
  createEntityAdapter<IManualLaunchTask>();

export const initialManualLaunchState: ManualLaunchTaskState =
  manualLaunchTaskAdapter.getInitialState({
    manualLaunchLoaded: false,
    page: 0,
    enableNextPage: true,
    processingRequestIdFilter: {
      key: 'processingRequestId',
      value: '',
      operator: '=rei=',
    },
    patientIDFilter: { key: 'patientID', value: '', operator: '=rei=' },
    accessionNumberFilter: {
      key: 'accessionNumber',
      value: '',
      operator: '=rei=',
    },
    useCaseFilter: { key: 'useCase', value: [], operator: '=in=' },
    statusFilter: { key: 'state', value: [], operator: '=in=' },
    typeFilter: { key: 'type', value: [], operator: '=in=' },
    originFilter: { key: 'origin.remoteAet', value: [], operator: '=in=' },
    csvFilter: {
      key: 'csvFileName',
      value: '',
      operator: '=rei=',
    },
  });

export const manualaunchTasksReducer = createReducer(
  initialManualLaunchState,

  on(manualLaunchTaskActions.INIT, (state) => ({
    ...state,
    manualLaunchLoaded: false,
    error: null,
  })),

  on(
    manualLaunchTaskActions.MANUAL_LAUNCH_TASKS_LOADED_SUCCESSFULLY,
    (state, { manualLaunchTasks }) =>
      manualLaunchTaskAdapter.setAll(manualLaunchTasks, {
        ...state,
        manualLaunchLoaded: true,
      })
  ),

  on(manualLaunchTaskActions.UPDATE_PAGE_NUMBER, (state, { page }) => ({
    ...state,
    page,
  })),

  on(
    manualLaunchTaskActions.SELECT_MANUAL_LAUNCH_TASK,
    (state, { selectedManualLaunchTask }) => ({
      ...state,
      selectedManualLaunchTask,
    })
  ),

  on(
    manualLaunchTaskActions.UPDATE_ENABLE_NEXT_PAGE,
    (state, { enableNextPage }) => ({ ...state, enableNextPage })
  ),

  on(
    manualLaunchTaskActions.UPDATE_PATIENTE_ID_FILTER,
    (state, { patientIDFilterValue }) => ({
      ...state,
      patientIDFilter: {
        ...state.patientIDFilter,
        value: patientIDFilterValue,
      },
    })
  ),

  on(
    manualLaunchTaskActions.UPDATE_PROCESSING_REQUEST_ID_FILTER,
    (state, { processingRequestIdFilterValue }) => ({
      ...state,
      processingRequestIdFilter: {
        ...state.processingRequestIdFilter,
        value: processingRequestIdFilterValue,
      },
    })
  ),

  on(
    manualLaunchTaskActions.UPDATE_ACCESSION_NUMBER_FILTER,
    (state, { accessionNumberFilterValue }) => ({
      ...state,
      accessionNumberFilter: {
        ...state.accessionNumberFilter,
        value: accessionNumberFilterValue,
      },
    })
  ),

  on(
    manualLaunchTaskActions.UPDATE_USE_CASE_FILTER,
    (state, { useCaseToFilter }) => {
      let useCaseFilterValue: string[] = [];
      if (state.useCaseFilter.value.includes(useCaseToFilter)) {
        useCaseFilterValue = state.useCaseFilter.value.filter(
          (state) => state !== useCaseToFilter
        );
      } else if (
        !state.useCaseFilter.value.includes(useCaseToFilter) &&
        useCaseToFilter !== 'all'
      ) {
        useCaseFilterValue = [...state.useCaseFilter.value, useCaseToFilter];
      }
      return {
        ...state,
        useCaseFilter: {
          ...state.useCaseFilter,
          value: useCaseFilterValue,
        },
      };
    }
  ),

  on(
    manualLaunchTaskActions.UPDATE_STATUS_FILTER,
    (state, { statusToFilter }) => {
      let statusFilterValue: string[] = [];
      if (state.statusFilter.value.includes(statusToFilter)) {
        statusFilterValue = state.statusFilter.value.filter(
          (state) => state !== statusToFilter
        );
      } else if (
        !state.statusFilter.value.includes(statusToFilter) &&
        statusToFilter !== 'all'
      ) {
        statusFilterValue = [...state.statusFilter.value, statusToFilter];
      }
      return {
        ...state,
        statusFilter: {
          ...state.statusFilter,
          value: statusFilterValue,
        },
      };
    }
  ),

  on(manualLaunchTaskActions.UPDATE_TYPE_FILTER, (state, { typeToFilter }) => {
    let typeFilterValue: string[] = [];
    if (state.typeFilter.value.includes(typeToFilter)) {
      typeFilterValue = state.typeFilter.value.filter(
        (type) => type !== typeToFilter
      );
    } else if (
      !state.typeFilter.value.includes(typeToFilter) &&
      typeToFilter !== 'all'
    ) {
      typeFilterValue = [...state.typeFilter.value, typeToFilter];
    }
    return {
      ...state,
      typeFilter: {
        ...state.typeFilter,
        value: typeFilterValue,
      },
    };
  }),

  on(
    manualLaunchTaskActions.UPDATE_ORIGIN_FILTER,
    (state, { originToFilter }) => {
      let originFilterValue: string[] = [];
      if (state.originFilter.value.includes(originToFilter)) {
        originFilterValue = state.originFilter.value.filter(
          (state) => state !== originToFilter
        );
      } else if (
        !state.originFilter.value.includes(originToFilter) &&
        originToFilter !== 'all'
      ) {
        originFilterValue = [...state.originFilter.value, originToFilter];
      }
      return {
        ...state,
        originFilter: {
          ...state.originFilter,
          value: originFilterValue,
        },
      };
    }
  ),

  on(
    manualLaunchTaskActions.UPDATE_CSV_FILTER,
    (state, { csvFilterValue }) => ({
      ...state,
      csvFilter: {
        ...state.csvFilter,
        value: csvFilterValue,
      },
    })
  )
);
