import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { ConfigService } from '../../service/config.service';
import { HomeActionConfigService } from '../../service/home-action-config.service';
import * as SharedActions from '../actions/shared.actions';

@Injectable()
export class SharedEffects {
  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private homeActionConfigService: HomeActionConfigService
  ) {}

  loadOrigins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadOrigins),
      mergeMap(() =>
        this.configService
          .getAvailableOrigins()
          .pipe(map((origins) => SharedActions.loadOriginsSuccess({ origins })))
      )
    )
  );

  loadUseCases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadUseCases),
      mergeMap(() =>
        this.configService
          .getAvailableUseCases()
          .pipe(
            map((useCases) => SharedActions.loadUseCasesSuccess({ useCases }))
          )
      )
    )
  );

  loadApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadApplications),
      mergeMap(() =>
        this.configService
          .getEnabledApplications()
          .pipe(
            map((applications) =>
              SharedActions.loadApplicationsSuccess({ applications })
            )
          )
      )
    )
  );

  loadHomeActionConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadHomeActionConfig),
      mergeMap(() =>
        this.homeActionConfigService
          .loadHomeActionConfig()
          .pipe(
            map((homeActions) =>
              SharedActions.loadHomeActionConfigSuccess({ homeActions })
            )
          )
      )
    )
  );
}
