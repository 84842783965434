import { createAction, props } from '@ngrx/store';
import { IExamPerUseCase } from '../../models/exam-per-use-case.model';

export const init = createAction('[ExamPerUseCase Page] Init');

export const loadExamsPerUseCaseCurrentPage = createAction(
  '[ExamPerUseCase Page] loadExamPerUseCaseCurrentPage'
);

export const loadExamsPerUseCaseNextPage = createAction(
  '[ExamPerUseCase Page] loadExamPerUseCaseNextPage'
);

export const updateEnableNextPage = createAction(
  '[ExamPerUseCase EnableNextPage] Update EnableNextPage',
  props<{ enableNextPage: boolean }>()
);

export const UPDATE_EXPANDED_ROW_EXAM_USE_CASE_ID = createAction(
  '[ExamPerUseCase updateExpandedId] Update Expanded Exam Use Case Id',
  props<{ expandedRowExamUseCaseId: string }>()
);

export const loadExamsPerUseCaseSuccess = createAction(
  '[ExamsPerUseCase/API] Load ExamsPerUseCase Success',
  props<{ examsPerUseCase: IExamPerUseCase[] }>()
);

export const selectExamPerUseCase = createAction(
  '[ExamsPerUseCase Page] Select examPerUseCase',
  props<{ selectedId: IExamPerUseCase['id'] }>()
);

export const updatePageNumber = createAction(
  '[ExamsPerUseCase Page] Update page number',
  props<{ page: number }>()
);

export const updatePatientIDFilter = createAction(
  '[ExamsPerUseCase Page] Update patient ID',
  props<{ patientIDFilterValue: string }>()
);

export const updateOriginFilter = createAction(
  '[ExamsPerUseCase Page] Update origin',
  props<{ originToFilter: string }>()
);

export const updateAccessionNumberFilter = createAction(
  '[ExamsPerUseCase Page] Update accession number',
  props<{ accessionNumberFilterValue: string }>()
);

export const updateUseCaseFilter = createAction(
  '[ExamsPerUseCase Page] Update useCase',
  props<{ useCaseToFilter: string }>()
);

export const updateApplicationFilter = createAction(
  '[ExamsPerUseCase Page] Update application',
  props<{ applicationToFilter: string }>()
);

export const updateStatusFilter = createAction(
  '[ExamsPerUseCase Page] Update state',
  props<{ statusToFilter: string }>()
);

export const updateStepFilter = createAction(
  '[ExamsPerUseCase Page] Update step',
  props<{ stepToFilter: string }>()
);

export const updateTypeFilter = createAction(
  '[ExamsPerUseCase Page] Update type',
  props<{ typeToFilter: string }>()
);

export const updateStudyDescriptionFilter = createAction(
  '[ExamsPerUseCase Page] Update study description',
  props<{ studyDescriptionFilterValue: string }>()
);

export const updateStudyStartDatetimeFilter = createAction(
  '[ExamsPerUseCase Page] Update study start datetime',
  props<{ studyStartDatetimeFilterValue: string }>()
);

export const updateStudyEndDatetimeFilter = createAction(
  '[ExamsPerUseCase Page] Update study end datetime',
  props<{ studyEndDatetimeFilterValue: string }>()
);

export const updateCreationStartDateFilter = createAction(
  '[ExamsPerUseCase Page] Update creation start date',
  props<{ creationStartDateFilterValue: string }>()
);

export const updateCreationEndDateFilter = createAction(
  '[ExamsPerUseCase Page] Update creation end date',
  props<{ creationEndDateFilterValue: string }>()
);
