import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderBarComponent } from './header-bar.component';
import { LogoutButtonModule } from '@incepto-gateway/shared/logout-button';
import { ResourceMonitorsViewsModule } from '@incepto-gateway/resource-monitors-views';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HeaderBarComponent],
  imports: [
    CommonModule,
    LogoutButtonModule,
    ResourceMonitorsViewsModule,
    RouterModule,
  ],
  exports: [HeaderBarComponent],
})
export class HeaderBarModule {}
