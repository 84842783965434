export interface IResourceMonitorElement {
  identifier: string;
  configEnabled: boolean;
  monitoringEnabled?: boolean;
  status?: boolean;
}

export interface DetailedResourceMonitorElement
  extends IResourceMonitorElement {
  className: string;
}

export type CategoryType = 'APPLICATION_SERVER' | 'SYSTEM' | 'MODALITY';

export type IResourceMonitors = {
  [key in CategoryType]: IResourceMonitorElement[];
};
