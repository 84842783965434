export enum ProcessingRequestType {
  STANDARD = 'STANDARD',
  PRIOR = 'PRIOR',
  CSV = 'CSV',
  PRIOR_CSV = 'PRIOR_CSV',
  COMPLAINT = 'COMPLAINT',
  PRIOR_COMPLAINT = 'PRIOR_COMPLAINT',
}
export const TYPE_MAP = {
  STANDARD: 'Auto',
  COMPLAINT: 'Complaint',
  CSV: 'Manual',
};

export const PROCESSING_REQUEST_TYPE_LIST = Object.entries(TYPE_MAP).map(
  ([value, type]) => ({ type, value })
);

export const TYPE_LIST = PROCESSING_REQUEST_TYPE_LIST.map((type) => type.value);

export type IPROCESSING_REQUEST_TYPE_LIST = typeof TYPE_LIST[number];
