import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AuthServiceRepositoryService,
  InceptoAuthCommonModule,
} from '@incepto/auth-common';
import { DevAuthService, InceptoAuthDevModule } from '@incepto/auth-dev';
import { InceptoLoginCommonModule } from '@incepto/login-common';
import { DevLoginComponent, InceptoLoginDevModule } from '@incepto/login-dev';
import { BasicAuthService } from './basic-auth.service';
import { BasicLoginComponent } from './basic-login.component';

const authInitializerFunction = (
  basicAuthService: BasicAuthService,
  devAuthService: DevAuthService
) => {
  const authRepo: AuthServiceRepositoryService =
    new AuthServiceRepositoryService();
  authRepo.putService('BASIC', basicAuthService);
  authRepo.putService('DEV', devAuthService as any);
  return authRepo;
};

@NgModule({
  declarations: [BasicLoginComponent],
  imports: [
    InceptoLoginCommonModule,
    InceptoLoginDevModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InceptoLoginDevModule.forRoot({
      roles: [{ key: 'ROLE_SERVICE', label: 'Service' }],
    }),
    InceptoLoginCommonModule.forRoot({
      firstLogin: new Map(),
      login: new Map([
        ['BASIC', BasicLoginComponent as any],
        ['DEV', DevLoginComponent as any],
      ]),
      forgotPassword: new Map(),
      verificationCode: new Map(),
    }),
    InceptoAuthDevModule,
    InceptoAuthCommonModule,
  ],
  providers: [
    BasicAuthService,
    {
      provide: AuthServiceRepositoryService,
      useFactory: authInitializerFunction,
      deps: [BasicAuthService, DevAuthService],
    },
  ],
})
export class InceptoLoginModule {}
