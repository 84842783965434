import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import {
  manualaunchTasksReducer,
  MANUAL_LAUNCH_TASKS_FEATURE_KEY,
} from './state/reducers/manual-launch.reducers';
import { EffectsModule } from '@ngrx/effects';
import { ManualLaunchTasksEffects } from './state/effects/manual-launch.effects';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      MANUAL_LAUNCH_TASKS_FEATURE_KEY,
      manualaunchTasksReducer
    ),
    EffectsModule.forFeature([ManualLaunchTasksEffects]),
  ],
})
export class ManualLaunchTasksDataAccessModule {}
