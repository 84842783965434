import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PATIENT_TO_EXCLUDE_KEY,
  PatientToExcludeState,
  patientToExcludeAdapter,
} from '../reducers/patient-to-exclude.reducers';

export const getPatientsToExcludeState =
  createFeatureSelector<PatientToExcludeState>(PATIENT_TO_EXCLUDE_KEY);

const { selectAll } = patientToExcludeAdapter.getSelectors();

export const getPatientsToExclude = createSelector(
  getPatientsToExcludeState,
  (state: PatientToExcludeState) => selectAll(state)
);

export const selectModeFolio = createSelector(
  getPatientsToExcludeState,
  (state: PatientToExcludeState) =>
    selectAll(state).filter((value) => value.mode === 'FOLIO')
);

export const selectModeDataCollection = createSelector(
  getPatientsToExcludeState,
  (state: PatientToExcludeState) =>
    selectAll(state).filter((value) => value.mode === 'DATA_COLLECTION')
);
