import { IState } from '@incepto-gateway/shared/status-list';

export interface IExamPerUseCaseDetails {
  id: string;
  createdDate?: string;
  modifiedDate?: string;
  seriesDetailList: ISeriesDetails[];
  priorSeriesDetailList: ISeriesDetails[];
  analysisSeries: { [analysisId: string]: string[] };
  analyzePartialStudy: boolean;
  pseudonymizedData: {
    pseudonymizedPatientID: string;
    pseudonymizedStudyInstanceUID: string;
    pseudonymizedAccessionNumber: string;
    pseudonymizationKey: string;
  };
}

export interface ISeriesDetails {
  state: IState;
  messages: string[];
  patientID: string;
  studyInstanceUID: string;
  seriesInstanceUID: string;
  accessionNumber: string;
  dicomTags: {
    PatientBirthDate?: string;
    InstitutionName?: string;
    StudyDate?: string;
    StudyDescription?: string;
    PatientName?: string;
    SeriesDescription?: string;
    PatientAge?: string;
    StudyTime?: string;
    PatientWeight?: string;
    PhotometricInterpretation?: string;
    PatientSex?: string;
    PerformedProcedureStepDescription?: string;
    PatientSize?: string;
    Modality?: string;
    BodyPartExamined?: string;
  };
  instanceDetailList: {
    state: IState;
    messages: string[];
    sopInstanceUID: string;
  }[];
}

export interface IExamPerUseCaseOutputs {
  analysisId: string;
  pseudonymizedSeriesInstanceUID?: string;
  resultType?: string;
  numberOfInstances?: number;
  outputAETs: IOutputAET[];
}

export interface IOutputAET {
  orthanc?: string;
  localAet?: string;
  remoteAet?: string;
}
