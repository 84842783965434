import { Component, Input, OnChanges } from '@angular/core';
import {
  DetailedResourceMonitorElement,
  IResourceMonitorElement,
} from '@incepto-gateway/resource-monitors-data-access';
import { SharedFacade } from '@incepto-gateway/shared/state';
import { ResourceMonitorStatus } from './model/resource-monitors.model';

@Component({
  selector: 'incepto-gateway-resource-monitors-details',
  templateUrl: './resource-monitors.details.component.html',
  styleUrls: ['./resource-monitors.details.component.scss'],
})
export class ResourceMonitorsDetailsComponent implements OnChanges {
  @Input() resourceMonitors: {
    key: string;
    value: IResourceMonitorElement[];
  }[];

  resourceMonitorsDetails: {
    key: string;
    value: DetailedResourceMonitorElement[];
  }[];

  applications$ = this.sharedFacade.enabledApplications$;

  ngOnChanges() {
    this.resourceMonitorsDetails = this.resourceMonitors.map(
      (resourceMonitor) => ({
        key: resourceMonitor.key,
        value: resourceMonitor.value.map(
          (resourceMonitorElement: IResourceMonitorElement) => ({
            ...resourceMonitorElement,
            className: this.getCssClassResourceMonitorElement(
              resourceMonitorElement
            ),
          })
        ),
      })
    );
  }

  getCssClassResourceMonitorElement(
    ressourceMonitElement: IResourceMonitorElement
  ): string {
    if (
      ressourceMonitElement.configEnabled &&
      ressourceMonitElement.monitoringEnabled &&
      !ressourceMonitElement.status
    ) {
      return ResourceMonitorStatus.RED;
    } else if (
      ressourceMonitElement.configEnabled &&
      ressourceMonitElement.monitoringEnabled &&
      ressourceMonitElement.status
    ) {
      return ResourceMonitorStatus.GREEN;
    } else if (
      ressourceMonitElement.configEnabled &&
      !ressourceMonitElement.monitoringEnabled
    ) {
      return ResourceMonitorStatus.BLUE;
    } else if (!ressourceMonitElement.configEnabled) {
      return ResourceMonitorStatus.GRAY;
    }
  }

  getStatus(resourceMonitors: DetailedResourceMonitorElement[]): string {
    if (
      resourceMonitors.some((rm) => rm.className === ResourceMonitorStatus.RED)
    ) {
      return ResourceMonitorStatus.RED;
    } else if (
      resourceMonitors.some(
        (rm) => rm.className === ResourceMonitorStatus.GREEN
      )
    ) {
      return ResourceMonitorStatus.GREEN;
    } else if (
      resourceMonitors.some((rm) => rm.className === ResourceMonitorStatus.BLUE)
    ) {
      return ResourceMonitorStatus.BLUE;
    } else {
      return ResourceMonitorStatus.GRAY;
    }
  }

  constructor(private sharedFacade: SharedFacade) {}
}
