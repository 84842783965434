import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IPatientToExclude } from '../../model/patient-to-exclude.model';
import * as PatientToExcludeActions from '../actions/patient-to-exclude.actions';
import * as PatientToExcludeSelector from '../selectors/patient-to-exclude.selectors';
@Injectable({ providedIn: 'root' })
export class PatientToExcludeFacade {
  constructor(private store: Store) {}

  patientsToExclude$ = this.store.pipe(
    select(PatientToExcludeSelector.getPatientsToExclude)
  );

  patientsToExcludeFolio$ = this.store.pipe(
    select(PatientToExcludeSelector.selectModeFolio)
  );

  patientsToExcludeDataCollection$ = this.store.pipe(
    select(PatientToExcludeSelector.selectModeDataCollection)
  );

  postPatientToExlude(patientToExlude: IPatientToExclude) {
    this.store.dispatch(
      PatientToExcludeActions.POST_PATIENT_TO_EXCLUDE({
        patientToExclude: patientToExlude,
      })
    );
  }

  getPatientToExlude() {
    this.store.dispatch(PatientToExcludeActions.LOAD_PATIENT_TO_EXCLUDE());
  }
}
