<ng-container>
  <ul class="resource-monitors">
    <li *ngFor="let category of resourceMonitorsDetails">
      <span
        class="resource-monitors__status {{ getStatus(category.value) }}"
      ></span>
    </li>
    <li>
      <span class="resource-monitors__status status-green"></span>
    </li>
    <div class="rmd">
      <ul class="rmd__list">
        <li *ngFor="let rmd of resourceMonitorsDetails">
          <h5 class="rmd__list-category" [ngSwitch]="getStatus(rmd.value)">
            <span
              class="rmd__list-category-info-1"
              *ngSwitchCase="'status-green'"
              >✔</span
            >
            <span
              class="rmd__list-category-info-2"
              *ngSwitchCase="'status-blue'"
              >?</span
            >
            <span class="rmd__list-category-info-3" *ngSwitchCase="'status-red'"
              >!</span
            >
            <div class="status {{ getStatus(rmd.value) }}"></div>
            <span class="text-{{ getStatus(rmd.value) }} "> {{ rmd.key }}</span>
          </h5>
          <div
            class="rmd__list-category-item"
            *ngFor="let rm of rmd?.value"
            [ngSwitch]="rm.className"
          >
            <span
              class="rmd__list-category-item-info-1"
              *ngSwitchCase="'status-green'"
              >✔</span
            >
            <span
              class="rmd__list-category-item-info-2"
              *ngSwitchCase="'status-blue'"
              >?</span
            >
            <span
              class="rmd__list-category-item-info-3"
              *ngSwitchCase="'status-red'"
              >!</span
            >
            <div class="status  {{ rm.className }}"></div>
            <span class="identifier-{{ rm.className }}">{{
              rm?.identifier
            }}</span>
          </div>
        </li>
        <li>
          <h5 class="rmd__list-category">
            <span class="rmd__list-category-info-1">✔</span>
            <div class="status status-green"></div>
            <span class="text-status-green">APPLICATIONS</span>
          </h5>
          <div
            class="rmd__list-category-item"
            *ngFor="let app of applications$ | async"
          >
            <span class="rmd__list-category-item-info-1">✔</span>
            <div class="status status-green"></div>
            <span class="identifier-status-green">{{
              app.label ? app.label : app.key
            }}</span>
          </div>
        </li>
      </ul>
    </div>
  </ul>
</ng-container>
