import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import * as fromComplaints from './state/reducers/complaints.reducers';
import { EffectsModule } from '@ngrx/effects';
import { ComplaintsEffects } from './state/effects/complaints.effects';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromComplaints.COMPLAINTS_KEY,
      fromComplaints.complaintsReducer
    ),
    EffectsModule.forFeature([ComplaintsEffects]),
  ],
})
export class ComplaintsDataAccessModule {}
