import { Injectable } from '@angular/core';
import { AbstractGatewayClient } from '@incepto-gateway/shared/common-gateway-client';
import {
  BackendConfigurationService,
  HttpHelperService,
} from '@incepto/common-clients';
import { UserSessionRepositoryService } from '@incepto/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { EBackendService } from '@incepto/common-models';
import { IExamPerUseCaseAnalysis } from '../models/exam-per-use-case-details-view.model';

@Injectable({
  providedIn: 'root',
})
export class AnalysisRelatedMonitoringService extends AbstractGatewayClient<IExamPerUseCaseAnalysis> {
  constructor(
    backendConfigurationService: BackendConfigurationService,
    userSessionRepositoryService: UserSessionRepositoryService,
    http: HttpClient,
    private httpHelperService: HttpHelperService
  ) {
    super(
      backendConfigurationService,
      userSessionRepositoryService,
      http,
      'analysis-related-monitoring'
    );
    this.buildClient();
  }

  getExamPerAnalysisId(analysisId: string) {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    const headers = new HttpHeaders().set('Accept', 'application/stream+json');
    httpOptions.headers = headers as any;

    return this.http.get<IExamPerUseCaseAnalysis>(
      `${this.backendConfigurationService.getBackendServiceUrl(
        EBackendService.GATEWAY_SERVICE
      )}/analysis-related-monitoring?search=analysisId==${analysisId}`,
      httpOptions
    );
  }
}
