import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BackendConfigurationService,
  HttpHelperService,
} from '@incepto/common-clients';
import { UserSessionRepositoryService } from '@incepto/core';
import { AbstractGatewayClient } from '@incepto-gateway/shared/common-gateway-client';
import { EBackendService } from '@incepto/common-models';
import { IPatientToExclude } from '../model/patient-to-exclude.model';
import { Observable, map, merge } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientToExcludeService extends AbstractGatewayClient<IPatientToExclude> {
  constructor(
    backendConfigurationService: BackendConfigurationService,
    userSessionRepositoryService: UserSessionRepositoryService,
    http: HttpClient,
    private httpHelperService: HttpHelperService
  ) {
    super(
      backendConfigurationService,
      userSessionRepositoryService,
      http,
      'personal-data-to-exclude'
    );
    this.buildClient();
  }

  postPatientToExclude(postPatientToExclude: IPatientToExclude) {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    return this.http.post<IPatientToExclude>(
      `${this.backendConfigurationService.getBackendServiceUrl(
        EBackendService.GATEWAY_SERVICE
      )}/personal-data-to-exclude?mode=${postPatientToExclude.mode}`,
      postPatientToExclude,
      httpOptions
    );
  }

  getStream(): Observable<IPatientToExclude> {
    return merge(
      super.getStream([{ key: 'mode', value: 'FOLIO' }]).pipe(
        map((value) => ({
          ...value,
          mode: 'FOLIO',
        }))
      ),
      super.getStream([{ key: 'mode', value: 'DATA_COLLECTION' }]).pipe(
        map((value) => ({
          ...value,
          mode: 'DATA_COLLECTION',
        }))
      )
    );
  }
}
