import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IComplaint } from '../../model/complaint.model';
import { complaintsActions } from '../actions/complaints-action.types';

export const COMPLAINTS_KEY = 'complaints';

export type RSQLFilter =
  | RSQLFilterRegexQuery
  | RSQLFilterInQuery
  | RSQLFilterGreaterOrEqualQuery
  | RSQLFilterLessOrEqualQuery;

type RSQLFilterRegexQuery = {
  key: string;
  value: string;
  operator: '=rei=';
};

type RSQLFilterInQuery = {
  key: string;
  value: string[];
  operator: '=in=';
};

type RSQLFilterGreaterOrEqualQuery = {
  key: string;
  value: string;
  operator: '=gte=';
};

type RSQLFilterLessOrEqualQuery = {
  key: string;
  value: string;
  operator: '=lte=';
};

export interface ComplaintsState extends EntityState<IComplaint> {
  complaintsLoaded: boolean;
  isCreated: boolean;
  selectedComplaint?: string | number;
  error?: string | null;
  page: number;
  enableNextPage: boolean;
  patientIDFilter: RSQLFilterRegexQuery;
  accessionNumberFilter: RSQLFilterRegexQuery;
  useCaseFilter: RSQLFilterInQuery;
  applicationFilter: RSQLFilterInQuery;
  ticketNumberFilter: RSQLFilterRegexQuery;
  pseudonymizedPIDFilter: RSQLFilterRegexQuery;
  originFilter: RSQLFilterInQuery;
  seriouslyAffectedFilter: RSQLFilterInQuery;
  creationStartDateFilter: RSQLFilterGreaterOrEqualQuery;
  creationEndDateFilter: RSQLFilterLessOrEqualQuery;
}

export const complaintsAdapter: EntityAdapter<IComplaint> =
  createEntityAdapter<IComplaint>();

export const initialComplaintsState: ComplaintsState =
  complaintsAdapter.getInitialState({
    complaintsLoaded: false,
    isCreated: false,
    page: 0,
    enableNextPage: true,
    patientIDFilter: { key: 'patientID', value: '', operator: '=rei=' },
    accessionNumberFilter: {
      key: 'accessionNumber',
      value: '',
      operator: '=rei=',
    },
    useCaseFilter: { key: 'useCase', value: [], operator: '=in=' },
    applicationFilter: { key: 'application', value: [], operator: '=in=' },
    ticketNumberFilter: { key: 'ticketNumber', value: '', operator: '=rei=' },
    originFilter: { key: 'origin.remoteAet', value: [], operator: '=in=' },
    creationStartDateFilter: {
      key: 'createdDate',
      value: '',
      operator: '=gte=',
    },
    creationEndDateFilter: { key: 'createdDate', value: '', operator: '=lte=' },
    seriouslyAffectedFilter: {
      key: 'seriouslyAffected',
      value: [],
      operator: '=in=',
    },
    pseudonymizedPIDFilter: {
      key: 'pseudonymizedPatientID',
      value: '',
      operator: '=rei=',
    },
  });

export const complaintsReducer = createReducer(
  initialComplaintsState,
  on(complaintsActions.INIT, (state) => ({
    ...state,
    complaintsLoaded: false,
    error: null,
  })),
  on(
    complaintsActions.COMPLAINTS_HAS_SUCCESSFULLY_LOADED,
    (state, { complaints }) =>
      complaintsAdapter.setAll(complaints, {
        ...state,
        complaintsLoaded: true,
      })
  ),
  on(
    complaintsActions.COMPLAINT_HAS_SUCCESSFULLY_CREATED,
    (state, { isCreated }) => ({
      ...state,
      isCreated,
    })
  ),
  on(complaintsActions.UPDATE_PAGE_NUMBER, (state, { page }) => ({
    ...state,
    page,
  })),

  on(complaintsActions.SELECT_COMPLAINT, (state, { selectedComplaint }) => ({
    ...state,
    selectedComplaint,
  })),

  on(
    complaintsActions.UPDATE_ENABLE_NEXT_PAGE,
    (state, { enableNextPage }) => ({ ...state, enableNextPage })
  ),

  on(
    complaintsActions.UPDATE_PATIENTE_ID_FILTER,
    (state, { patientIDFilterValue }) => ({
      ...state,
      patientIDFilter: {
        ...state.patientIDFilter,
        value: patientIDFilterValue,
      },
    })
  ),

  on(
    complaintsActions.UPDATE_USE_CASE_FILTER,
    (state, { useCaseFilterValue }) => {
      let useCaseToFilter: string[] = [];
      if (state.useCaseFilter.value.includes(useCaseFilterValue)) {
        useCaseToFilter = state.useCaseFilter.value.filter(
          (state) => state !== useCaseFilterValue
        );
      } else if (
        !state.useCaseFilter.value.includes(useCaseFilterValue) &&
        useCaseFilterValue !== 'all'
      ) {
        useCaseToFilter = [...state.useCaseFilter.value, useCaseFilterValue];
      }
      return {
        ...state,
        useCaseFilter: {
          ...state.useCaseFilter,
          value: useCaseToFilter,
        },
      };
    }
  ),

  on(
    complaintsActions.UPDATE_APPLICATION_FILTER,
    (state, { applicationFilterValue }) => {
      let applicationToFilter: string[] = [];
      if (state.applicationFilter.value.includes(applicationFilterValue)) {
        applicationToFilter = state.applicationFilter.value.filter(
          (state) => state !== applicationFilterValue
        );
      } else if (
        !state.applicationFilter.value.includes(applicationFilterValue) &&
        applicationFilterValue !== 'all'
      ) {
        applicationToFilter = [
          ...state.applicationFilter.value,
          applicationFilterValue,
        ];
      }
      return {
        ...state,
        applicationFilter: {
          ...state.applicationFilter,
          value: applicationToFilter,
        },
      };
    }
  ),

  on(
    complaintsActions.UPDATE_TICKET_NUMBER_FILTER,
    (state, { ticketNumberFilterValue }) => ({
      ...state,
      ticketNumberFilter: {
        ...state.ticketNumberFilter,
        value: ticketNumberFilterValue,
      },
    })
  ),
  on(
    complaintsActions.UPDATE_PSEUDONYMIZE_PID_FILTER,
    (state, { pseudonymizedPIDFilterFilterValue }) => ({
      ...state,
      pseudonymizedPIDFilter: {
        ...state.pseudonymizedPIDFilter,
        value: pseudonymizedPIDFilterFilterValue,
      },
    })
  ),

  on(complaintsActions.UPDATE_ORIGIN_FILTER, (state, { originToFilter }) => {
    let originFilterValue: string[] = [];
    if (state.originFilter.value.includes(originToFilter)) {
      originFilterValue = state.originFilter.value.filter(
        (state) => state !== originToFilter
      );
    } else if (
      !state.originFilter.value.includes(originToFilter) &&
      originToFilter !== 'all'
    ) {
      originFilterValue = [...state.originFilter.value, originToFilter];
    }
    return {
      ...state,
      originFilter: {
        ...state.originFilter,
        value: originFilterValue,
      },
    };
  }),

  on(
    complaintsActions.UPDATE_SERIOUSLY_AFFECTED_FILTER,
    (state, { seriouslyAffectedToFilter }) => {
      let seriouslyAffectedFilterValue: string[] = [];
      if (
        state.seriouslyAffectedFilter.value.includes(seriouslyAffectedToFilter)
      ) {
        seriouslyAffectedFilterValue =
          state.seriouslyAffectedFilter.value.filter(
            (state) => state !== seriouslyAffectedToFilter
          );
      } else if (
        !state.seriouslyAffectedFilter.value.includes(
          seriouslyAffectedToFilter
        ) &&
        seriouslyAffectedToFilter !== 'all'
      ) {
        seriouslyAffectedFilterValue = [
          ...state.seriouslyAffectedFilter.value,
          seriouslyAffectedToFilter,
        ];
      }
      return {
        ...state,
        seriouslyAffectedFilter: {
          ...state.seriouslyAffectedFilter,
          value: seriouslyAffectedFilterValue,
        },
      };
    }
  ),

  on(
    complaintsActions.UPDATE_CREATION_START_DATE_FILTER,
    (state, { creationStartDateFilterValue }) => ({
      ...state,
      creationStartDateFilter: {
        ...state.creationStartDateFilter,
        value: creationStartDateFilterValue,
      },
    })
  ),

  on(
    complaintsActions.UPDATE_CREATION_END_DATE_FILTER,
    (state, { creationEndDateFilterValue }) => ({
      ...state,
      creationEndDateFilter: {
        ...state.creationEndDateFilter,
        value: creationEndDateFilterValue,
      },
    })
  )
);
