import { ServerType } from '@incepto-gateway/shared/state';
import { IState } from '@incepto-gateway/shared/status-list';
import { IExamPerUseCaseDetails } from './exam-per-use-case-details.model';
import {
  IAnalysisMonitoringInfo,
  IExamPerUseCase,
} from './exam-per-use-case.model';

export interface IExamPerUseCaseDetailsView {
  examPerUseCase: IExamPerUseCase;
  analysesMonitoring: IExamPerUseCaseAnalysis[];
  examPerUseCaseDetails: IExamPerUseCaseDetails;
}

export interface IExamPerUseCaseAnalysis extends IAnalysisMonitoringInfo {
  inputs: IInputStudies[];
  outputs?: IOutputAnalysisResults[];
}

export interface IInputStudies {
  studyInstanceUID: string;
  accessionNumber?: string;
  studyDate?: string;
  studyTime?: string;
  studyDescription?: string;
  series: IInputSeries[];
  analysisIdAndstudyInstanceUID: string;
  numberOfInstances: number | 'N/A';
}

export interface IInputSeries {
  seriesInstanceUID: string;
  seriesDescription?: string;
  modality?: string;
  status?: IState;
  seriesDate?: string;
  seriesTime?: string;
  numberOfInstancesinSeries?: number | 'N/A';
  numberOfSelectedInstancesinSeries?: number;
  messages: string[] | null;
  numberOfInstancesPerSeries: number | 'N/A';
}

export interface IOutputAnalysisResults {
  resultType?: string;
  modality?: string;
  seriesDescription?: string;
  numberOfInstancesinSeries?: number | 'N/A';

  resultsAETs?: IResultAET[];
  state: IState;
  errorMessage?: string;
  server: ServerType;
}

export interface IResultAET {
  aet?: string;
  status?: IState;
  storeDateTime?: string;
  errorMessage?: string;
}
