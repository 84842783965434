import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromSharedReducers from './store/reducers/shared.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedEffects } from './store/effects/shared.effects';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSharedReducers.SHARED_STATE,
      fromSharedReducers.sharedReducer
    ),
    EffectsModule.forFeature([SharedEffects]),
    MonacoEditorModule.forRoot(),
  ],
})
export class SharedStateModule {}
