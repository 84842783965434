import { IState } from '@incepto-gateway/shared/status-list';

export interface IManualLaunchTask {
  id: string;
  accessionNumber: string;
  createdDate: string;
  csvFileName: string;
  expirationDate: string;
  inputPathToResource: string;
  message: string;
  modality: string;
  modifiedDate: string;
  origin: {
    localAet: string;
    orthanc: string;
    remoteAet: string;
  };
  outputPathToResource: string;
  patientID: string;
  processingRequestIds: string[];
  studyDate: string;
  state: IState;
  type: string;
  useCase: string;
}
