import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionRepositoryService } from '@incepto/core';

@Component({
  selector: 'incepto-gateway-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss'],
})
export class LogoutButtonComponent {
  constructor(
    private _router: Router,
    private userSessionRepositoryService: UserSessionRepositoryService
  ) {}

  logout(): void {
    this.userSessionRepositoryService.logout(true);
    this._router.navigateByUrl(`/login`);
  }
}
