import { Component, OnInit } from '@angular/core';
import { ResourceMonitorsFacade } from '@incepto-gateway/resource-monitors-data-access';
import { map } from 'rxjs/operators';
import { SharedFacade } from '@incepto-gateway/shared/state';

@Component({
  selector: 'incepto-gateway-resource-monitors',
  templateUrl: './resource-monitors.component.html',
  styleUrls: ['./resource-monitors.component.scss'],
})
export class ResourceMonitorsComponent implements OnInit {
  resourceMonitors$ = this.resourceMonitorsFacade.resourceMonitors$.pipe(
    map((resourcesMonitors) => resourcesMonitors[0])
  );
  constructor(
    private resourceMonitorsFacade: ResourceMonitorsFacade,
    private sharedFacade: SharedFacade
  ) {}

  ngOnInit(): void {
    this.resourceMonitorsFacade.init();
    this.sharedFacade.loadApplications();
    this.sharedFacade.loadUseCases();
    this.sharedFacade.loadOrigins();
  }
}
