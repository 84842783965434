import { createAction, props } from '@ngrx/store';
import { IComplaint } from '../../model/complaint.model';

export const INIT = createAction('[Complaints Page Init] Initialisation');

export const LOAD_COMPLAINTS_CURRENT_PAGE = createAction(
  '[Complaints Current Page] Load Complaints Current Page'
);

export const LOAD_COMPLAINTS_NEXT_PAGE = createAction(
  '[Complaints Next Page] Load Complaints Next Page'
);

export const COMPLAINTS_HAS_SUCCESSFULLY_LOADED = createAction(
  '[Complaints Has Successfully Loaded / API] Complaints Has Successfully Loaded',
  props<{ complaints: IComplaint[] }>()
);

export const CREATE_COMPLAINT = createAction(
  '[UI COMPLAINT FORM] POST COMPLAINT FORM',
  props<{ complaint: IComplaint }>()
);

export const COMPLAINT_HAS_SUCCESSFULLY_CREATED = createAction(
  '[Complaint has Created/ API] Complaint Has Successfully Created',
  props<{ response: string; isCreated: boolean }>()
);

export const CLOSE_COMPLAINT = createAction(
  '[UI COMPLAINT FORM] CLOSE COMPLAINT FORM',
  props<{ isCreated: boolean }>()
);

export const COMPLAINT_POST_FAILURE = createAction(
  '[Complaint / API Failed] POST Failure',
  props<{ error: string }>()
);
export const UPDATE_PAGE_NUMBER = createAction(
  '[Complaints Page] Update page number',
  props<{ page: number }>()
);

export const UPDATE_ENABLE_NEXT_PAGE = createAction(
  '[Complaints Enable Next Page] Update EnableNextPage',
  props<{ enableNextPage: boolean }>()
);

export const SELECT_COMPLAINT = createAction(
  '[Complaints Page] Select Complaint',
  props<{ selectedComplaint: IComplaint['patientID'] }>()
);

export const UPDATE_PATIENTE_ID_FILTER = createAction(
  '[Complaints Page] Update patient ID',
  props<{ patientIDFilterValue: string }>()
);

export const UPDATE_ACCESSION_NUMBER_FILTER = createAction(
  '[Complaints Page] Update accession number',
  props<{ accessionNumberFilterValue: string }>()
);

export const UPDATE_USE_CASE_FILTER = createAction(
  '[Complaints Page] Update useCase',
  props<{ useCaseFilterValue: string }>()
);

export const UPDATE_APPLICATION_FILTER = createAction(
  '[Complaints Page] Update application',
  props<{ applicationFilterValue: string }>()
);

export const UPDATE_TICKET_NUMBER_FILTER = createAction(
  '[Complaints Page] Update Ticket Number',
  props<{ ticketNumberFilterValue: string }>()
);

export const UPDATE_PSEUDONYMIZE_PID_FILTER = createAction(
  '[Complaints Page] Update Pseudonymized PID',
  props<{ pseudonymizedPIDFilterFilterValue: string }>()
);

export const UPDATE_ORIGIN_FILTER = createAction(
  '[Complaints Page] Update origin',
  props<{ originToFilter: string }>()
);

export const UPDATE_SERIOUSLY_AFFECTED_FILTER = createAction(
  '[Complaints Page] Update seriously Affected',
  props<{ seriouslyAffectedToFilter: string }>()
);

export const UPDATE_CREATION_START_DATE_FILTER = createAction(
  '[Complaints Page] Update creation start date',
  props<{ creationStartDateFilterValue: string }>()
);

export const UPDATE_CREATION_END_DATE_FILTER = createAction(
  '[Complaints Page] Update creation end date',
  props<{ creationEndDateFilterValue: string }>()
);
