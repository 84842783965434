import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import * as ResourceMonitorsActions from './resource-monitors.actions';
import * as ResourceMonitorsSelectors from './resource-monitors.selectors';

@Injectable()
export class ResourceMonitorsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(
    select(ResourceMonitorsSelectors.getProcessingRequestsLoaded)
  );

  resourceMonitors$ = this.store.pipe(
    select(ResourceMonitorsSelectors.getAllProcessingRequests)
  );

  constructor(private store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(ResourceMonitorsActions.init());
  }
}
