import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

import {
  PATIENT_TO_EXCLUDE_KEY,
  patientToExludeReducer,
} from './state/reducers/patient-to-exclude.reducers';
import { EffectsModule } from '@ngrx/effects';
import { PatientToExcludeEffects } from './state/effects/patient-to-exclude.effects';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(PATIENT_TO_EXCLUDE_KEY, patientToExludeReducer),
    EffectsModule.forFeature([PatientToExcludeEffects]),
  ],
})
export class PatientToExludeDataAccessModule {}
