import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  resourceMonitorsAdapter,
  ResourceMonitorsPartialState,
  RESOURCE_MONITORS_FEATURE_KEY,
  State,
} from './resource-monitors.reducer';

// Lookup the 'ProcessingRequests' feature state managed by NgRx
export const getResourceMonitorsState = createFeatureSelector<
  ResourceMonitorsPartialState,
  State
>(RESOURCE_MONITORS_FEATURE_KEY);

const { selectAll } = resourceMonitorsAdapter.getSelectors();

export const getProcessingRequestsLoaded = createSelector(
  getResourceMonitorsState,
  (state: State) => state.loaded
);

export const getProcessingRequestsError = createSelector(
  getResourceMonitorsState,
  (state: State) => state.error
);

export const getAllProcessingRequests = createSelector(
  getResourceMonitorsState,
  (state: State) => selectAll(state)
);
