import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ResourceMonitorsActions from './resource-monitors.actions';
import { select, Store } from '@ngrx/store';
import { ResourceMonitorsService } from '../client-resource-monitors.service';
import { ResourceMonitorsPartialState } from './resource-monitors.reducer';
import * as SharedState from '@incepto-gateway/shared/state';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class resourceMonitorsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceMonitorsActions.init),
      switchMap(() =>
        this.store$.pipe(
          select(SharedState.selectAutoRefresh),
          switchMap((autoRefresh) => (!autoRefresh ? of(0) : timer(0, 5000))),
          switchMap(() => this.resourceMonitorsService.getStream()),
          map((resourceMonitors) =>
            ResourceMonitorsActions.loadResourceMonitorsSuccess({
              resourceMonitors,
            })
          ),
          catchError((error: string) => {
            this.toastr.error(error);
            return of(SharedState.DISABLE_AUTO_REFRESH());
          })
        )
      )
    )
  );
  constructor(
    private store$: Store<ResourceMonitorsPartialState>,
    private actions$: Actions,
    private resourceMonitorsService: ResourceMonitorsService,
    private toastr: ToastrService
  ) {}
}
