import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import * as ExamsPerUseCaseActions from '../actions/exam-per-use-case.actions';
import * as ExamsPerUseCaseSelectors from '../selectors/exam-per-use-case.selectors';

@Injectable({ providedIn: 'root' })
export class ExamPerUseCaseFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.getExamsPerUseCaseLoaded)
  );

  page$ = this.store.pipe(select(ExamsPerUseCaseSelectors.selectPage));

  enableNextPage$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectEnableNextPage)
  );

  patientIDFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectPatientIDFilterValue)
  );

  accessionNumberFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectAccessionNumberFilterValue)
  );

  useCaseFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectUseCaseFilterValue)
  );

  applicationFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectApplicationFilterValue)
  );

  statusFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectStatusFilterValue)
  );

  stepFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectStepFilterValue)
  );

  typeFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectTypeFilterValue)
  );

  originFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectOriginFilterValue)
  );

  studyDescriptionFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectStudyDescriptionFilterValue)
  );

  studyStartDatetimeFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectStudyStartDatetimeFilterValue)
  );

  studyEndDatetimeFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectStudyEndDatetimeFilterValue)
  );

  creationStartDateFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectCreationStartDateFilterValue)
  );

  creationEndDateFilterValue$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.selectCreationEndDateFilterValue)
  );

  allExamPerUseCase$ = this.store.pipe(
    select(ExamsPerUseCaseSelectors.getAllExamsPerUseCase)
  );

  constructor(private store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(ExamsPerUseCaseActions.init());
  }

  select(selectedId: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.selectExamPerUseCase({ selectedId })
    );
  }

  setExpandedRowExamUseCaseId(expandedRowExamUseCaseId: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.UPDATE_EXPANDED_ROW_EXAM_USE_CASE_ID({
        expandedRowExamUseCaseId,
      })
    );
  }

  setPage(page: number) {
    this.store.dispatch(ExamsPerUseCaseActions.updatePageNumber({ page }));
  }

  setPatientIdFilter(patientIDFilterValue: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updatePatientIDFilter({ patientIDFilterValue })
    );
  }

  setOriginFilter(originToFilter: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateOriginFilter({ originToFilter })
    );
  }

  setAccessionNumberFilter(accessionNumberFilterValue: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateAccessionNumberFilter({
        accessionNumberFilterValue,
      })
    );
  }

  setUseCaseFilter(useCaseToFilter: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateUseCaseFilter({ useCaseToFilter })
    );
  }

  setApplicationFilter(applicationToFilter: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateApplicationFilter({ applicationToFilter })
    );
  }

  setStatusFilter(statusToFilter: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateStatusFilter({ statusToFilter })
    );
  }

  setStepFilter(stepToFilter: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateStepFilter({ stepToFilter })
    );
  }

  setTypeFilter(typeToFilter: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateTypeFilter({ typeToFilter })
    );
  }

  setStudyDescriptionFilter(studyDescriptionFilterValue: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateStudyDescriptionFilter({
        studyDescriptionFilterValue,
      })
    );
  }

  setStudyStartDatetimeFilter(studyStartDatetimeFilterValue: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateStudyStartDatetimeFilter({
        studyStartDatetimeFilterValue,
      })
    );
  }

  setStudyEndDatetimeFilter(studyEndDatetimeFilterValue: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateStudyEndDatetimeFilter({
        studyEndDatetimeFilterValue,
      })
    );
  }

  setCreationStartDateFilter(creationStartDateFilterValue: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateCreationStartDateFilter({
        creationStartDateFilterValue,
      })
    );
  }

  setCreationEndDateFilter(creationEndDateFilterValue: string) {
    this.store.dispatch(
      ExamsPerUseCaseActions.updateCreationEndDateFilter({
        creationEndDateFilterValue,
      })
    );
  }
  updateExamsPerUseCaseCurrentPage() {
    this.store.dispatch(
      ExamsPerUseCaseActions.loadExamsPerUseCaseCurrentPage()
    );
  }
}
