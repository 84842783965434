import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ManualLaunchTaskState,
  MANUAL_LAUNCH_TASKS_FEATURE_KEY,
  manualLaunchTaskAdapter,
} from '../reducers/manual-launch.reducers';

export const getManualLaunchTaskState =
  createFeatureSelector<ManualLaunchTaskState>(MANUAL_LAUNCH_TASKS_FEATURE_KEY);

const { selectAll, selectEntities } = manualLaunchTaskAdapter.getSelectors();

export const getManualLaunchLoaded = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.manualLaunchLoaded
);

export const selectPage = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.page
);

export const selectEnableNextPage = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.enableNextPage
);

export const getManualLaunchTasks = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => selectAll(state)
);

export const selectProcessingRequestIdFilter = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.processingRequestIdFilter
);

export const selectProcessingRequestIdFilterValue = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.processingRequestIdFilter.value
);

export const selectPatientIDFilter = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.patientIDFilter
);

export const selectPatientIDFilterValue = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.patientIDFilter.value
);

export const selectAccessionNumberFilter = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.accessionNumberFilter
);

export const selectAccessionNumberFilterValue = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.accessionNumberFilter.value
);

export const selectUseCaseFilter = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.useCaseFilter
);

export const selectUseCaseFilterValue = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.useCaseFilter.value
);

export const selectStatusFilter = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.statusFilter
);

export const selectStatusFilterValue = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.statusFilter.value
);

export const selectTypeFilter = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.typeFilter
);

export const selectTypeFilterValue = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.typeFilter.value
);

export const getManualLaunchTasksEntities = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => selectEntities(state)
);

export const getSelectedManualLaunchTaskId = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.selectedManualLaunchTask
);

export const getSelected = createSelector(
  getManualLaunchTaskState,
  getSelectedManualLaunchTaskId,
  (entities, getSelectedManualLaunchTask) =>
    getSelectedManualLaunchTask && entities[getSelectedManualLaunchTask]
);

export const selectOriginFilter = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.originFilter
);

export const selectOriginFilterValue = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.originFilter.value
);

export const selectCsvFilter = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.csvFilter
);

export const selectCsvFilterValue = createSelector(
  getManualLaunchTaskState,
  (state: ManualLaunchTaskState) => state.csvFilter.value
);
