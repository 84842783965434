import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendConfigurationService } from '@incepto/common-clients';
import { EBackendService } from '@incepto/common-models';
import { BasicUser } from './basic-user';

@Injectable({
  providedIn: 'root',
})
export class LoginoutService {
  private loginEndpoint = `${this.backendConfigurationService.getBackendServiceUrl(
    EBackendService.GATEWAY_SERVICE
  )}/login`;

  private logoutEndpoint = `${this.backendConfigurationService.getBackendServiceUrl(
    EBackendService.GATEWAY_SERVICE
  )}/signout`;

  private testLoginEndpoint = `${this.backendConfigurationService.getBackendServiceUrl(
    EBackendService.GATEWAY_SERVICE
  )}/actuator/health`;

  constructor(
    public backendConfigurationService: BackendConfigurationService,
    public http: HttpClient
  ) {}

  login(username: string, password: string): Promise<BasicUser> {
    const headers = {
      Authorization: `Basic ${window.btoa(`${username}:${password}`)}`,
    };
    return this.http
      .get<BasicUser>(this.loginEndpoint, { headers, withCredentials: true })
      .toPromise();
  }

  logout(): Promise<void> {
    return this.http.post<void>(this.logoutEndpoint, null).toPromise();
  }

  testLogin() {
    return this.http
      .get(this.testLoginEndpoint, {
        observe: 'response',
        withCredentials: true,
      })
      .toPromise();
  }
}
