import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as SharedSelectors from './selectors/shared.selectors';
import * as SharedActions from './actions/shared.actions';
import { Observable } from 'rxjs';
import { IHomeAction } from '../models/home-action.model';
import { IOrigin } from '../models/origin.model';
import { IApplication } from '../models/application.model';

@Injectable({ providedIn: 'root' })
export class SharedFacade {
  autoRefresh$ = this.store.pipe(select(SharedSelectors.selectAutoRefresh));

  constructor(private store: Store) {}

  origins$: Observable<IOrigin[]> = this.store.pipe(
    select(SharedSelectors.getOrigins)
  );
  useCases$: Observable<string[]> = this.store.pipe(
    select(SharedSelectors.getUseCases)
  );
  useCasesForComplaint$: Observable<string[]> = this.store.pipe(
    select(SharedSelectors.getUseCasesForComplaint)
  );

  enabledApplications$: Observable<IApplication[]> = this.store.pipe(
    select(SharedSelectors.selectEnabledApplications)
  );
  homeActionConfig$: Observable<IHomeAction[]> = this.store.pipe(
    select(SharedSelectors.getHomeActionConfig)
  );

  toggleAutoRefresh() {
    this.store.dispatch(SharedActions.TOGGLE_AUTO_REFRESH());
  }

  loadOrigins() {
    this.store.dispatch(SharedActions.loadOrigins());
  }

  loadUseCases() {
    this.store.dispatch(SharedActions.loadUseCases());
  }

  loadApplications() {
    this.store.dispatch(SharedActions.loadApplications());
  }

  loadHomeActionConfig() {
    this.store.dispatch(SharedActions.loadHomeActionConfig());
  }
}
