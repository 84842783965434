import { createAction, props } from '@ngrx/store';
import { IManualLaunchTask } from '../../models/manual-launch-task.model';

export const INIT = createAction('[Manual Launch Task Page Init] Init');

export const POST_CSV_CONFIGURATION = createAction(
  '[UI Manual Launch task] Upload CSV and Configuration',
  props<{ csv: File; csvConfig: Blob }>()
);
export const CSV_CONFIGURATION_FILES_POSTED_SUCCESSFULLY = createAction(
  '[CSV And Configuration Files Posted Successfully / API Success] POST Success',
  props<{ payload: string }>()
);
export const CSV_CONFIGURATION_FILES_POST_FAILURE = createAction(
  '[CSV And Configuration Files Post Failure / API Failed] POST Failure',
  props<{ error: string }>()
);

export const LOAD_MANUAL_LAUNCH_TASKS_CURRENT_PAGE = createAction(
  '[Manual Launch task Page] Load Manual Launch task Current Page'
);
export const LOAD_MANUAL_LAUNCH_TASKS_NEXT_PAGE = createAction(
  '[Manual Launch task Page] Load Manual Launch task Next Page'
);
export const MANUAL_LAUNCH_TASKS_LOADED_SUCCESSFULLY = createAction(
  '[Manual Launch task Page SUCCESS / API] Load Manual Launch task Success',
  props<{ manualLaunchTasks: IManualLaunchTask[] }>()
);

export const UPDATE_PAGE_NUMBER = createAction(
  '[Manual Launch task Page] Update page number',
  props<{ page: number }>()
);

export const UPDATE_ENABLE_NEXT_PAGE = createAction(
  '[Manual Launch task EnableNextPage] Update EnableNextPage',
  props<{ enableNextPage: boolean }>()
);

export const SELECT_MANUAL_LAUNCH_TASK = createAction(
  '[Manual Launch task Page] Select Manual Launch task Task',
  props<{ selectedManualLaunchTask: IManualLaunchTask['id'] }>()
);

export const UPDATE_PATIENTE_ID_FILTER = createAction(
  '[Manual Launch task Page] Update patient ID',
  props<{ patientIDFilterValue: string }>()
);

export const UPDATE_ACCESSION_NUMBER_FILTER = createAction(
  '[Manual Launch task Page] Update accession number',
  props<{ accessionNumberFilterValue: string }>()
);

export const UPDATE_PROCESSING_REQUEST_ID_FILTER = createAction(
  '[Manual Launch task Page] Update processing request ID',
  props<{ processingRequestIdFilterValue: string }>()
);

export const UPDATE_USE_CASE_FILTER = createAction(
  '[Manual Launch task Page] Update useCase',
  props<{ useCaseToFilter: string }>()
);

export const UPDATE_STATUS_FILTER = createAction(
  '[Manual Launch task Page] Update state',
  props<{ statusToFilter: string }>()
);

export const UPDATE_TYPE_FILTER = createAction(
  '[Manual Launch task Page] Update type',
  props<{ typeToFilter: string }>()
);

export const UPDATE_ORIGIN_FILTER = createAction(
  '[Manual Launch Page] Update origin',
  props<{ originToFilter: string }>()
);

export const UPDATE_CSV_FILTER = createAction(
  '[Manual Launch task Page] Update csv number',
  props<{ csvFilterValue: string }>()
);
