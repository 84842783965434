export const RESULT_TYPES_LIST = [
  'NONE',
  'INCEPTO_REPORT_JSON',
  'REPORT_JSON',
  'REPORT_DICOM_SR',
  'REPORT_DICOM_SCPT',
  'REPORT_DICOM_PDF',
  'REPORT_PDF',
  'OVERLAY_DICOM_SCPT',
  'OVERLAY_MHA',
  'OVERLAY_DICOM_GSPS',
  'OVERLAY_DICOM_SEG_IOD',
  'DERIVED_DICOM_IMAGE',
  'DERIVED_DICOM_KO',
  'MEAS_TABLE_DICOM_SCPT',
  'DICOM_SC',
  'DICOM_SR',
  'DICOM_GSPS',
  'DICOM_CAD_SR',
  'DICOM_PDF_REPORT',
  'DICOM_IMAGE_REPORT',
  'DICOM_SEGMENTED_VOLUME',
  'PDF_REPORT',
  'IMAGE_REPORT',
  'JSON',
];

export type IRESULT_TYPES = typeof RESULT_TYPES_LIST[number];
