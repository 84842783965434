import {
  IAnalysisMonitoringInfo,
  IExamPerUseCase,
} from './exam-per-use-case.model';
import { IState } from '@incepto-gateway/shared/status-list';

export interface IExamPerUseCaseDetailsView {
  examPerUseCase: IExamPerUseCase;
  analysesMonitoring: IExamPerUseCaseAnalysis[];
}

export interface IExamPerUseCaseAnalysis extends IAnalysisMonitoringInfo {
  inputs: IInputStudies[];
  outputs?: IOutputAnalysisResults[];
}

export interface IInputStudies {
  studyInstanceUID: string;
  accessionNumber?: string;
  studyDate?: string;
  studyTime?: string;
  studyDescription?: string;

  series: IInputSeries[];

  analysisIdAndstudyInstanceUID: string;
}

export interface IInputSeries {
  seriesInstanceUID: string;
  seriesDescription?: string;
  modality?: string;
  status?: IState;
  seriesDate?: string;
  seriesTime?: string;
  numberOfInstancesinSeries?: number;
  numberOfSelectedInstancesinSeries?: number;

  messages: string[] | null;
}

export interface IOutputAnalysisResults {
  resultType?: string;
  modality?: string;
  seriesDescription?: string;
  numberOfInstancesinSeries?: number;

  resultsAETs?: IResultAET[];
}

export interface IResultAET {
  aet?: string;
  status?: IState;
  storeDateTime?: string;
}
