import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { UserSessionRepositoryService, UserSession } from '@incepto/core';
import { AbstractAuthService } from '@incepto/auth-common';
import { LoginoutService } from './loginout.service';
import { BasicUser } from './basic-user';

const BASIC_LOCAL_STORAGE_AUTH_KEY = 'BASIC_LOCAL_STORAGE_AUTH_KEY';

@Injectable()
export class BasicAuthService extends AbstractAuthService {
  constructor(
    private router: Router,
    private logInOutService: LoginoutService,
    userSessionRepositoryService: UserSessionRepositoryService
  ) {
    super(userSessionRepositoryService);
  }

  login(userLogin: string, password: string): Promise<void> {
    return this.logInOutService.login(userLogin, password).then((user) => {
      localStorage.setItem(BASIC_LOCAL_STORAGE_AUTH_KEY, JSON.stringify(user));
      this.userSessionRepositoryService.setUserSession(
        this.buildUserSession(user)
      );
    });
  }

  buildUserSession(user: BasicUser): UserSession {
    return {
      userId: user.name,
      cookieSession: true,
      availableGroups: [
        {
          role: user.authorities[0].authority,
          application: undefined,
          unrestrictedAccesses: [],
        },
      ],
    };
  }

  completeLogout() {
    localStorage.removeItem(BASIC_LOCAL_STORAGE_AUTH_KEY);
    return this.logInOutService.logout().then(() => {
      this.router.navigate(['/login']);
    });
  }

  authFromCache(): Promise<void> {
    const localStorageToken =
      localStorage.getItem(BASIC_LOCAL_STORAGE_AUTH_KEY) || undefined;
    if (localStorageToken !== undefined) {
      this.userSessionRepositoryService.setUserSession(
        this.buildUserSession(JSON.parse(localStorageToken))
      );

      return this.logInOutService
        .testLogin()
        .then((response) => {
          if (response.status === 401 || response.status === 403) {
            localStorage.removeItem(BASIC_LOCAL_STORAGE_AUTH_KEY);
            this.userSessionRepositoryService.logout(true);
          }
        })
        .catch(() => {
          localStorage.removeItem(BASIC_LOCAL_STORAGE_AUTH_KEY);
          this.userSessionRepositoryService.logout(true);
        });
    }
  }

  refresh(): Promise<void> {
    return Promise.resolve();
  }
}
