import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractGatewayClient } from '@incepto-gateway/shared/common-gateway-client';
import {
  BackendConfigurationService,
  HttpHelperService,
} from '@incepto/common-clients';
import { UserSessionRepositoryService } from '@incepto/core';
import { Observable } from 'rxjs';
import { IHomeAction } from '../models/home-action.model';

@Injectable({
  providedIn: 'root',
})
export class HomeActionConfigService extends AbstractGatewayClient<IHomeAction> {
  constructor(
    backendConfigurationService: BackendConfigurationService,
    userSessionRepositoryService: UserSessionRepositoryService,
    private httpHelperService: HttpHelperService,
    http: HttpClient
  ) {
    super(backendConfigurationService, userSessionRepositoryService, http, '');
    this.buildClient();
  }

  loadHomeActionConfig(): Observable<IHomeAction[]> {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    return this.http.get<IHomeAction[]>(
      '/assets/home-action-buttons.json',
      httpOptions
    );
  }
}
