import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ExamPerUseCasePartialState,
  examsPerUseCaseAdapter,
  EXAMS_PER_USE_CASE_FEATURE_KEY,
  ExamPerUseCaseState,
} from '../reducers/exam-per-use-case.reducer';

export const getExamsPerUseCaseState = createFeatureSelector<
  ExamPerUseCasePartialState,
  ExamPerUseCaseState
>(EXAMS_PER_USE_CASE_FEATURE_KEY);

const { selectAll, selectEntities } = examsPerUseCaseAdapter.getSelectors();

export const selectStatusFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.statusFilter
);

export const selectStatusFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.statusFilter.value
);

export const getExamsPerUseCaseLoaded = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.loaded
);

export const selectPage = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.page
);

export const selectEnableNextPage = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.enableNextPage
);

export const selectPatientIDFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.patientIDFilter
);

export const selectPatientIDFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.patientIDFilter.value
);

export const selectAccessionNumberFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.accessionNumberFilter
);

export const selectAccessionNumberFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.accessionNumberFilter.value
);

export const selectUseCaseFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.useCaseFilter
);

export const selectUseCaseFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.useCaseFilter.value
);

export const selectApplicationFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.applicationFilter
);

export const selectApplicationFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.applicationFilter.value
);

export const selectStepFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.stepFilter
);

export const selectStepFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.stepFilter.value
);

export const selectTypeFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.typeFilter
);

export const selectTypeFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.typeFilter.value
);
export const selectOriginFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.originFilter
);

export const selectOriginFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.originFilter.value
);

export const selectStudyDescriptionFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.studyDescriptionFilter
);

export const selectStudyDescriptionFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.studyDescriptionFilter.value
);

export const getAllExamsPerUseCase = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => selectAll(state)
);

export const getExamsPerUseCaseEntities = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => selectEntities(state)
);

export const selectStudyStartDatetimeFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.studyStartDatetimeFilter
);

export const selectStudyStartDatetimeFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.studyStartDatetimeFilter.value
);

export const selectStudyEndDatetimeFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.studyEndDatetimeFilter
);

export const selectStudyEndDatetimeFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.studyEndDatetimeFilter.value
);

export const selectCreationStartDateFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.creationStartDateFilter
);

export const selectCreationStartDateFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.creationStartDateFilter.value
);

export const selectCreationEndDateFilter = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.creationEndDateFilter
);

export const selectCreationEndDateFilterValue = createSelector(
  getExamsPerUseCaseState,
  (state: ExamPerUseCaseState) => state.creationEndDateFilter.value
);
