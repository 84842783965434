export interface IComplaintTask {
  id: string;
  createdDate: string;
  modifiedDate: string;
  expirationDate: string;
  organizationId: string;
  siteId: string;
  application: string;
  origin: {
    orthanc: string;
    localAet: string;
    remoteAet: string;
  };
  useCase: string;
  comments: string;
  seriouslyAffected: boolean;
  emailToInform: string;
  ticketNumber: string;
  patientID: string;
  pseudonymizedPatientID: string;
  accessionNumber: string;
  studyInstanceUID?: string;
}

export interface IComplaint {
  patientID: string;
  accessionNumber: string;
  studyInstanceUID?: string;
  useCase: string;
  origin: string;
  comments: string;
  seriouslyAffected: boolean;
  emailToInform: string;
  ticketNumber: string;
}
