import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as SharedState from '@incepto-gateway/shared/state';
import { IQueryParam } from '@incepto/common-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators';
import { IComplaint } from '../../model/complaint.model';
import { ComplaintsService } from '../../service/complaints.service';
import { complaintsActions } from '../actions/complaints-action.types';
import { ComplaintsState, RSQLFilter } from '../reducers/complaints.reducers';
import * as ComplaintsSelectors from '../selectors/complaints.selectors';

const MAX_ELEMENT_PER_PAGE = 15;

@Injectable({
  providedIn: 'root',
})
export class ComplaintsEffects {
  rsqlFilters$: Observable<IQueryParam> = combineLatest([
    this.complaintsStore$.pipe(
      select(ComplaintsSelectors.selectPatientIDFilter)
    ),
    this.complaintsStore$.pipe(
      select(ComplaintsSelectors.selectAccessionNumberFilter)
    ),
    this.complaintsStore$.pipe(select(ComplaintsSelectors.selectUseCaseFilter)),
    this.complaintsStore$.pipe(
      select(ComplaintsSelectors.selectTicketNumberFilter)
    ),
    this.complaintsStore$.pipe(
      select(ComplaintsSelectors.selectPseudonymizedPIDFilter)
    ),
    this.complaintsStore$.pipe(
      select(ComplaintsSelectors.selectApplicationFilter)
    ),
    this.complaintsStore$.pipe(select(ComplaintsSelectors.selectOriginFilter)),
    this.complaintsStore$.pipe(
      select(ComplaintsSelectors.selectSeriouslyAffectedFilter)
    ),
    this.complaintsStore$.pipe(
      select(ComplaintsSelectors.selectCreationStartDateFilter)
    ),
    this.complaintsStore$.pipe(
      select(ComplaintsSelectors.selectCreationEndDateFilter)
    ),
  ]).pipe(map((rsqlFilters) => this.buildSearchRSQLFilter(rsqlFilters)));

  timer$: Observable<number> = this.complaintsStore$.pipe(
    select(SharedState.selectAutoRefresh),
    switchMap((autoRefresh) => {
      return !autoRefresh ? of(0) : timer(0, 5000);
    })
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(complaintsActions.INIT),
      switchMap(() => [
        complaintsActions.LOAD_COMPLAINTS_CURRENT_PAGE(),
        complaintsActions.LOAD_COMPLAINTS_NEXT_PAGE(),
      ])
    )
  );

  getComplaintsCurrentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(complaintsActions.LOAD_COMPLAINTS_CURRENT_PAGE),

      switchMap(() => {
        return combineLatest([
          this.buildCompleteQueryParamsObservable(
            this.buildPageAndSizeQueryParamsObs('currentPage'),
            this.rsqlFilters$
          ),
          this.timer$,
        ]).pipe(
          switchMap(([queryParams]) =>
            this.complaintsService.getStream(queryParams).pipe(
              toArray(),
              map((complaints: IComplaint[]) => {
                return complaintsActions.COMPLAINTS_HAS_SUCCESSFULLY_LOADED({
                  complaints,
                });
              }),
              catchError((error: string) => {
                this.toastr.error(error);
                return of(SharedState.DISABLE_AUTO_REFRESH());
              })
            )
          )
        );
      })
    )
  );

  getComplaintsNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(complaintsActions.LOAD_COMPLAINTS_NEXT_PAGE),
      switchMap(() => {
        return combineLatest([
          this.buildCompleteQueryParamsObservable(
            this.buildPageAndSizeQueryParamsObs('nextPage'),
            this.rsqlFilters$
          ),
          this.timer$,
        ]).pipe(
          switchMap(([queryParams, _]) =>
            this.complaintsService.getStream(queryParams).pipe(
              toArray(),
              map((complaints) =>
                complaintsActions.UPDATE_ENABLE_NEXT_PAGE({
                  enableNextPage: complaints.length > 0,
                })
              ),
              catchError((error: string) => {
                this.toastr.error(error);
                return of(SharedState.DISABLE_AUTO_REFRESH());
              })
            )
          )
        );
      })
    )
  );

  createComplaint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(complaintsActions.CREATE_COMPLAINT),
      concatMap((action) => {
        return this.complaintsService.postComplaint(action.complaint).pipe(
          map(() => {
            this.toastr.success('Complaint created successfully');
            return complaintsActions.COMPLAINT_HAS_SUCCESSFULLY_CREATED({
              response: 'Complaint created successfully',
              isCreated: true,
            });
          }),
          catchError((error) => {
            this.toastr.error(error.error.status, error.error.message);
            this.router.navigateByUrl('complaint');
            return of(
              complaintsActions.COMPLAINT_POST_FAILURE({
                error,
              })
            );
          })
        );
      })
    )
  );

  closeComplaint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(complaintsActions.CLOSE_COMPLAINT),
      map(() => {
        return complaintsActions.COMPLAINT_HAS_SUCCESSFULLY_CREATED({
          response: 'Complaint form closed successfully',
          isCreated: false,
        });
      })
    )
  );

  buildPageAndSizeQueryParamsObs(
    pageToConsider: 'currentPage' | 'nextPage'
  ): Observable<IQueryParam[]> {
    return this.complaintsStore$.pipe(
      select(ComplaintsSelectors.selectPage),
      map((page) => [
        { key: 'size', value: MAX_ELEMENT_PER_PAGE },
        {
          key: 'page',
          value: pageToConsider === 'currentPage' ? page : page + 1,
        },
        { key: 'sort', value: 'createdDate,desc' },
      ])
    );
  }

  buildSearchRSQLFilter(paramsToFilter: RSQLFilter[]): IQueryParam {
    return {
      key: 'search',
      value: paramsToFilter
        .filter((param) => param.value && param.value.length > 0)
        .map((filter) => this.buildSearchFilter(filter))
        .join(';'),
    };
  }

  buildSearchFilter(filter: RSQLFilter) {
    if (
      filter.operator === '=rei=' ||
      filter.operator === '=gte=' ||
      filter.operator === '=lte='
    ) {
      return `${filter.key}${filter.operator}${filter.value}`;
    } else if (filter.operator === '=in=') {
      return `${filter.key}${filter.operator}(${filter.value.join(',')})`;
    }
  }

  buildCompleteQueryParamsObservable(
    pageObservable: Observable<IQueryParam[]>,
    rsqlSearchFilterObservable: Observable<IQueryParam>
  ): Observable<IQueryParam[]> {
    return combineLatest([
      pageObservable,
      rsqlSearchFilterObservable.pipe(
        tap((_) =>
          this.complaintsStore$.dispatch(
            complaintsActions.UPDATE_PAGE_NUMBER({ page: 0 })
          )
        )
      ),
    ]).pipe(
      map(([page, rsqlFilter]) =>
        [...page, rsqlFilter].filter((queryParam) => queryParam.value !== '')
      )
    );
  }

  constructor(
    private complaintsStore$: Store<ComplaintsState>,
    private complaintsService: ComplaintsService,
    private actions$: Actions,
    private toastr: ToastrService,
    private router: Router
  ) {}
}
