import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ManualLaunchActions from '../actions/manual-launch-task.actions';
import * as ManualLaunchSelectors from '../selectors/manual-launch.selectors';

@Injectable({ providedIn: 'root' })
export class ManualLaunchTaskFacade {
  page$ = this.store.pipe(select(ManualLaunchSelectors.selectPage));

  enableNextPage$ = this.store.pipe(
    select(ManualLaunchSelectors.selectEnableNextPage)
  );

  manualLaunchTasks$ = this.store.pipe(
    select(ManualLaunchSelectors.getManualLaunchTasks)
  );

  processingRequestIdFilterValue$ = this.store.pipe(
    select(ManualLaunchSelectors.selectProcessingRequestIdFilterValue)
  );
  patientIDFilterValue$ = this.store.pipe(
    select(ManualLaunchSelectors.selectPatientIDFilterValue)
  );
  accessionNumberFilterValue$ = this.store.pipe(
    select(ManualLaunchSelectors.selectAccessionNumberFilterValue)
  );
  useCaseFilterValue$ = this.store.pipe(
    select(ManualLaunchSelectors.selectUseCaseFilterValue)
  );
  statusFilterValue$ = this.store.pipe(
    select(ManualLaunchSelectors.selectStatusFilterValue)
  );
  typeFilterValue$ = this.store.pipe(
    select(ManualLaunchSelectors.selectTypeFilterValue)
  );

  selectedManualLanchTask$ = this.store.pipe(
    select(ManualLaunchSelectors.getSelected)
  );

  originFilterValue$ = this.store.pipe(
    select(ManualLaunchSelectors.selectOriginFilterValue)
  );

  csvFilterValue$ = this.store.pipe(
    select(ManualLaunchSelectors.selectCsvFilterValue)
  );

  constructor(private store: Store) {}

  uploadCsvAndConfiguration(csv: File, csvConfig: Blob) {
    this.store.dispatch(
      ManualLaunchActions.POST_CSV_CONFIGURATION({ csv, csvConfig })
    );
  }

  init() {
    this.store.dispatch(ManualLaunchActions.INIT());
  }

  setPage(page: number) {
    this.store.dispatch(ManualLaunchActions.UPDATE_PAGE_NUMBER({ page }));
  }

  select(selectedManualLaunchTask: string) {
    this.store.dispatch(
      ManualLaunchActions.SELECT_MANUAL_LAUNCH_TASK({
        selectedManualLaunchTask,
      })
    );
  }

  setPatientIdFilter(patientIDFilterValue: string) {
    this.store.dispatch(
      ManualLaunchActions.UPDATE_PATIENTE_ID_FILTER({ patientIDFilterValue })
    );
  }

  setProcessingRequestIdFilter(processingRequestIdFilterValue: string) {
    this.store.dispatch(
      ManualLaunchActions.UPDATE_PROCESSING_REQUEST_ID_FILTER({
        processingRequestIdFilterValue,
      })
    );
  }

  setAccessionNumberFilter(accessionNumberFilterValue: string) {
    this.store.dispatch(
      ManualLaunchActions.UPDATE_ACCESSION_NUMBER_FILTER({
        accessionNumberFilterValue,
      })
    );
  }

  setUseCaseFilter(useCaseToFilter: string) {
    this.store.dispatch(
      ManualLaunchActions.UPDATE_USE_CASE_FILTER({ useCaseToFilter })
    );
  }

  setStatusFilter(statusToFilter: string) {
    this.store.dispatch(
      ManualLaunchActions.UPDATE_STATUS_FILTER({ statusToFilter })
    );
  }

  setTypeFilter(typeToFilter: string) {
    this.store.dispatch(
      ManualLaunchActions.UPDATE_TYPE_FILTER({ typeToFilter })
    );
  }

  setOriginFilter(originToFilter: string) {
    this.store.dispatch(
      ManualLaunchActions.UPDATE_ORIGIN_FILTER({ originToFilter })
    );
  }

  setCsvFilter(csvFilterValue: string) {
    this.store.dispatch(
      ManualLaunchActions.UPDATE_CSV_FILTER({
        csvFilterValue,
      })
    );
  }
}
