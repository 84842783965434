import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  COMPLAINTS_KEY,
  ComplaintsState,
  complaintsAdapter,
} from '../reducers/complaints.reducers';

export const getComplaintsState =
  createFeatureSelector<ComplaintsState>(COMPLAINTS_KEY);

const { selectAll, selectEntities } = complaintsAdapter.getSelectors();

export const getComplaintsLoaded = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.complaintsLoaded
);

export const getIsCreated = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.isCreated
);
export const getComplaints = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => selectAll(state)
);

export const selectPage = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.page
);

export const selectEnableNextPage = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.enableNextPage
);

export const selectPatientIDFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.patientIDFilter
);

export const selectPatientIDFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.patientIDFilter.value
);

export const selectAccessionNumberFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.accessionNumberFilter
);

export const selectAccessionNumberFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.accessionNumberFilter.value
);

export const selectUseCaseFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.useCaseFilter
);

export const selectUseCaseFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.useCaseFilter.value
);

export const selectApplicationFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.applicationFilter
);

export const selectApplicationFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.applicationFilter.value
);

export const selectTicketNumberFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.ticketNumberFilter
);

export const selectTicketNumberFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.ticketNumberFilter.value
);

export const selectPseudonymizedPIDFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.pseudonymizedPIDFilter
);

export const selectPseudonymizedPIDFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.pseudonymizedPIDFilter.value
);

export const getComplaintsEntities = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => selectEntities(state)
);

export const getSelectedComplaintId = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.selectedComplaint
);

export const getSelected = createSelector(
  getComplaintsState,
  getSelectedComplaintId,
  (entities, getSelectedComplaint) =>
    getSelectedComplaint && entities[getSelectedComplaint]
);

export const selectOriginFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.originFilter
);

export const selectOriginFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.originFilter.value
);

export const selectSeriouslyAffectedFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.seriouslyAffectedFilter
);

export const selectSeriouslyAffectedFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.seriouslyAffectedFilter.value
);

export const selectCreationStartDateFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.creationStartDateFilter
);

export const selectCreationStartDateFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.creationStartDateFilter.value
);

export const selectCreationEndDateFilter = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.creationEndDateFilter
);

export const selectCreationEndDateFilterValue = createSelector(
  getComplaintsState,
  (state: ComplaintsState) => state.creationEndDateFilter.value
);
