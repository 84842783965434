import { createAction, props } from '@ngrx/store';
import { IPatientToExclude } from '../../model/patient-to-exclude.model';

export const POST_PATIENT_TO_EXCLUDE = createAction(
  '[PATIENT TO EXCLUDE] POST PATIENT TO EXCLUDE',
  props<{ patientToExclude: IPatientToExclude }>()
);

export const PATIENT_TO_EXCLUDE_HAS_SUCCESSFULLY_POSTED = createAction(
  '[PATIENT TO EXCLUDE / API SUCCESS] PATIENT TO EXLUDE HAS SUCCESSFULLY POSTED',
  props<{ response: string; patientToExcludePosted: boolean }>()
);

export const PATIENT_TO_EXCLUDE_POST_FAILURE = createAction(
  '[PATIENT TO EXCLUDE / API FAILED] POST FAILURE',
  props<{ error: string }>()
);

export const LOAD_PATIENT_TO_EXCLUDE = createAction(
  '[PATIENT TO EXCLUDE] GET PATIENT TO EXCLUDE'
);

export const PATIENTS_TO_EXCLUDE_WERE_SUCCESSFULLY_LOADED = createAction(
  '[PATIENTS TO EXCLUDE / API SUCCESS] PATIENTS TO EXLUDE WERE SUCCESSFULLY FETCHED',
  props<{ patientsToExclude: IPatientToExclude[] }>()
);

export const PATIENTS_TO_EXCLUDE_LOADED_FAILURE = createAction(
  '[PATIENT TO EXCLUDE / API FAILED] FETCH FAILURE',
  props<{ error: string }>()
);
