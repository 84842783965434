import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IPatientToExclude } from '../../model/patient-to-exclude.model';
import * as PatientToExludeActions from '../actions/patient-to-exclude.actions';
export const PATIENT_TO_EXCLUDE_KEY = 'patient-to-exclude';

export interface PatientToExcludeState extends EntityState<IPatientToExclude> {
  patientToExcludePosted: boolean;
  patientToExcudeLoaded: boolean;
}

export const patientToExcludeAdapter: EntityAdapter<IPatientToExclude> =
  createEntityAdapter<IPatientToExclude>({
    selectId: (patientToExclude) =>
      `${patientToExclude.patientID}##${
        patientToExclude.accessionNumber ?? 'NA'
      }##${patientToExclude.mode ?? 'NA'}`,
  });

export const initialPatientToExcludeState =
  patientToExcludeAdapter.getInitialState({
    patientToExcludePosted: false,
    patientToExcudeLoaded: false,
  });

export const patientToExludeReducer = createReducer(
  initialPatientToExcludeState,
  on(
    PatientToExludeActions.PATIENT_TO_EXCLUDE_HAS_SUCCESSFULLY_POSTED,
    (state, { patientToExcludePosted }) => ({
      ...state,
      patientToExcludePosted,
    })
  ),

  on(
    PatientToExludeActions.PATIENTS_TO_EXCLUDE_WERE_SUCCESSFULLY_LOADED,
    (state, { patientsToExclude }) =>
      patientToExcludeAdapter.setAll(patientsToExclude, {
        ...state,
        patientToExcudeLoaded: true,
      })
  )
);
