import { Injectable } from '@angular/core';
import * as SharedState from '@incepto-gateway/shared/state';
import { IQueryParam } from '@incepto/common-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, of, timer } from 'rxjs';
import { catchError, map, switchMap, tap, toArray } from 'rxjs/operators';
import { ExamPerUseCaseService } from '../../service/client-exam-per-use-case.service';
import * as ExamsPerUseCaseActions from '../actions/exam-per-use-case.actions';
import {
  ExamPerUseCasePartialState,
  RSQLFilter,
} from '../reducers/exam-per-use-case.reducer';
import * as ExamsPerUseCaseSelectors from '../selectors/exam-per-use-case.selectors';

import { ToastrService } from 'ngx-toastr';
import { ProcessingRequestType } from '../../models/processing-request-type-list.model';
const MAX_ELEMENT_PER_PAGE = 15;

@Injectable()
export class ExamPerUseCaseEffects {
  rsqlFilters$: Observable<IQueryParam> = combineLatest([
    this.store$.pipe(select(ExamsPerUseCaseSelectors.selectPatientIDFilter)),
    this.store$.pipe(
      select(ExamsPerUseCaseSelectors.selectAccessionNumberFilter)
    ),
    this.store$.pipe(select(ExamsPerUseCaseSelectors.selectUseCaseFilter)),
    this.store$.pipe(select(ExamsPerUseCaseSelectors.selectStatusFilter)),
    this.store$.pipe(select(ExamsPerUseCaseSelectors.selectStepFilter)),
    this.store$.pipe(select(ExamsPerUseCaseSelectors.selectTypeFilter)),
    this.store$.pipe(select(ExamsPerUseCaseSelectors.selectOriginFilter)),
    this.store$.pipe(select(ExamsPerUseCaseSelectors.selectApplicationFilter)),
    this.store$.pipe(
      select(ExamsPerUseCaseSelectors.selectStudyDescriptionFilter)
    ),
    this.store$.pipe(
      select(ExamsPerUseCaseSelectors.selectStudyStartDatetimeFilter)
    ),
    this.store$.pipe(
      select(ExamsPerUseCaseSelectors.selectStudyEndDatetimeFilter)
    ),
    this.store$.pipe(
      select(ExamsPerUseCaseSelectors.selectCreationStartDateFilter)
    ),
    this.store$.pipe(
      select(ExamsPerUseCaseSelectors.selectCreationEndDateFilter)
    ),
  ]).pipe(
    map((rsqlFilters) =>
      this.buildSearchRSQLFilterWithFilteringOutPriorTypes(rsqlFilters)
    )
  );

  timer$: Observable<number> = this.store$.pipe(
    select(SharedState.selectAutoRefresh),
    switchMap((autoRefresh) => {
      return !autoRefresh ? of(0) : timer(0, 5000);
    })
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExamsPerUseCaseActions.init),
      switchMap(() => [
        ExamsPerUseCaseActions.loadExamsPerUseCaseCurrentPage(),
        ExamsPerUseCaseActions.loadExamsPerUseCaseNextPage(),
      ])
    )
  );

  loadExamPerUseCaseCurrentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExamsPerUseCaseActions.loadExamsPerUseCaseCurrentPage),
      switchMap(() => {
        return combineLatest([
          this.buildCompleteQueryParamsObservable(
            this.buildPageAndSizeQueryParamsObs('currentPage'),
            this.rsqlFilters$
          ),
          this.timer$,
        ]).pipe(
          switchMap(([queryParams, _]) =>
            this.examPerUseCaseService.getStream(queryParams).pipe(
              toArray(),
              map((examsPerUseCase) => {
                return ExamsPerUseCaseActions.loadExamsPerUseCaseSuccess({
                  examsPerUseCase,
                });
              }),
              catchError((error: string) => {
                this.toastr.error(error);
                return of(SharedState.DISABLE_AUTO_REFRESH());
              })
            )
          )
        );
      })
    )
  );

  loadExamsPerUseCaseNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExamsPerUseCaseActions.loadExamsPerUseCaseNextPage),
      switchMap(() => {
        return combineLatest([
          this.buildCompleteQueryParamsObservable(
            this.buildPageAndSizeQueryParamsObs('nextPage'),
            this.rsqlFilters$
          ),
          this.timer$,
        ]).pipe(
          switchMap(([queryParams, _]) =>
            this.examPerUseCaseService.getStream(queryParams).pipe(
              toArray(),
              map((examsPerUseCase) =>
                ExamsPerUseCaseActions.updateEnableNextPage({
                  enableNextPage: examsPerUseCase.length > 0,
                })
              ),
              catchError((error: string) => {
                this.toastr.error(error);
                return of(SharedState.DISABLE_AUTO_REFRESH());
              })
            )
          )
        );
      })
    )
  );

  buildPageAndSizeQueryParamsObs(
    pageToConsider: 'currentPage' | 'nextPage'
  ): Observable<IQueryParam[]> {
    return this.store$.pipe(
      select(ExamsPerUseCaseSelectors.selectPage),
      map((page) => [
        { key: 'size', value: MAX_ELEMENT_PER_PAGE },
        {
          key: 'page',
          value: pageToConsider === 'currentPage' ? page : page + 1,
        },
        { key: 'sort', value: 'createdDate,desc' },
      ])
    );
  }

  buildSearchRSQLFilterWithFilteringOutPriorTypes(
    paramsToFilter: RSQLFilter[]
  ): IQueryParam {
    const priorTypeFilter: RSQLFilter = {
      key: 'type',
      value: [
        ProcessingRequestType.PRIOR,
        ProcessingRequestType.PRIOR_CSV,
        ProcessingRequestType.PRIOR_COMPLAINT,
      ],
      operator: '=nin=',
    };
    const filter = {
      key: 'search',
      value: [...paramsToFilter, priorTypeFilter]
        .filter((param) => param.value && param.value.length > 0)
        .map((filter) => this.buildSearchFilter(filter))
        .join(';'),
    };
    return filter;
  }

  buildSearchFilter(filter: RSQLFilter) {
    if (
      filter.operator === '=rei=' ||
      filter.operator === '!=' ||
      filter.operator === '=gte=' ||
      filter.operator === '=lte='
    ) {
      return `${filter.key}${filter.operator}${filter.value}`;
    } else if (filter.operator === '=in=' || filter.operator === '=nin=') {
      return `${filter.key}${filter.operator}(${filter.value.join(',')})`;
    }
  }

  buildCompleteQueryParamsObservable(
    pageObservable: Observable<IQueryParam[]>,
    rsqlSearchFilterObservable: Observable<IQueryParam>
  ): Observable<IQueryParam[]> {
    return combineLatest([
      pageObservable,
      rsqlSearchFilterObservable.pipe(
        tap((_) =>
          this.store$.dispatch(
            ExamsPerUseCaseActions.updatePageNumber({ page: 0 })
          )
        )
      ),
    ]).pipe(
      map(([page, rsqlFilter]) =>
        [...page, rsqlFilter].filter((queryParam) => queryParam.value !== '')
      )
    );
  }

  constructor(
    private store$: Store<ExamPerUseCasePartialState>,
    private actions$: Actions,
    private examPerUseCaseService: ExamPerUseCaseService,
    private toastr: ToastrService
  ) {}
}
