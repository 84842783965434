import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IComplaint } from '../../model/complaint.model';
import * as ComplaintsActions from '../actions/complaints.actions';
import * as ComplaintsSelectors from '../selectors/complaints.selectors';

@Injectable({ providedIn: 'root' })
export class ComplaintsFacade {
  page$ = this.store.pipe(select(ComplaintsSelectors.selectPage));

  enableNextPage$ = this.store.pipe(
    select(ComplaintsSelectors.selectEnableNextPage)
  );

  complaints$ = this.store.pipe(select(ComplaintsSelectors.getComplaints));

  isCreated$ = this.store.pipe(select(ComplaintsSelectors.getIsCreated));

  patientIDFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectPatientIDFilterValue)
  );

  accessionNumberFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectAccessionNumberFilterValue)
  );

  useCaseFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectUseCaseFilterValue)
  );

  applicationFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectApplicationFilterValue)
  );

  ticketNumberFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectTicketNumberFilterValue)
  );

  pseudonymizedPIDFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectPseudonymizedPIDFilterValue)
  );

  originFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectOriginFilterValue)
  );

  seriouslyAffectedFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectSeriouslyAffectedFilterValue)
  );

  creationStartDateFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectCreationStartDateFilterValue)
  );

  creationEndDateFilterValue$ = this.store.pipe(
    select(ComplaintsSelectors.selectCreationEndDateFilterValue)
  );

  selectedComplaint$ = this.store.pipe(select(ComplaintsSelectors.getSelected));

  constructor(private store: Store) {}

  init() {
    this.store.dispatch(ComplaintsActions.INIT());
  }

  createComplaint(complaint: IComplaint) {
    this.store.dispatch(ComplaintsActions.CREATE_COMPLAINT({ complaint }));
  }

  closeComplaint(isCreated: boolean) {
    this.store.dispatch(ComplaintsActions.CLOSE_COMPLAINT({ isCreated }));
  }

  setPage(page: number) {
    this.store.dispatch(ComplaintsActions.UPDATE_PAGE_NUMBER({ page }));
  }

  select(selectedComplaint: string) {
    this.store.dispatch(
      ComplaintsActions.SELECT_COMPLAINT({ selectedComplaint })
    );
  }
  setPatientIdFilter(patientIDFilterValue: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_PATIENTE_ID_FILTER({ patientIDFilterValue })
    );
  }

  setAccessionNumberFilter(accessionNumberFilterValue: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_ACCESSION_NUMBER_FILTER({
        accessionNumberFilterValue,
      })
    );
  }

  setUseCaseFilter(useCaseFilterValue: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_USE_CASE_FILTER({ useCaseFilterValue })
    );
  }

  setApplicationFilter(applicationFilterValue: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_APPLICATION_FILTER({
        applicationFilterValue: applicationFilterValue,
      })
    );
  }

  setOriginFilter(originToFilter: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_ORIGIN_FILTER({ originToFilter })
    );
  }

  setSeriouslyAffectedFilter(seriouslyAffectedToFilter: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_SERIOUSLY_AFFECTED_FILTER({
        seriouslyAffectedToFilter,
      })
    );
  }

  setTicketNumberFilter(ticketNumberFilterValue: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_TICKET_NUMBER_FILTER({ ticketNumberFilterValue })
    );
  }

  setPseudonymizedPIDFilter(pseudonymizedPIDFilterFilterValue: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_PSEUDONYMIZE_PID_FILTER({
        pseudonymizedPIDFilterFilterValue,
      })
    );
  }

  setCreationStartDateFilter(creationStartDateFilterValue: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_CREATION_START_DATE_FILTER({
        creationStartDateFilterValue,
      })
    );
  }

  setCreationEndDateFilter(creationEndDateFilterValue: string) {
    this.store.dispatch(
      ComplaintsActions.UPDATE_CREATION_END_DATE_FILTER({
        creationEndDateFilterValue,
      })
    );
  }
}
