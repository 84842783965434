import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PatientToExcludeActions from '../actions/patient-to-exclude.actions';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { PatientToExcludeService } from '../../service/patient-to-exclude.service';
import { Observable, of, timer } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as SharedState from '@incepto-gateway/shared/state';
import { PatientToExcludeState } from '../reducers/patient-to-exclude.reducers';
import { IPatientToExclude } from '../../model/patient-to-exclude.model';
@Injectable({
  providedIn: 'root',
})
export class PatientToExcludeEffects {
  timer$: Observable<number> = this.patientsToExludeStore$.pipe(
    select(SharedState.selectAutoRefresh),
    switchMap((autoRefresh) => {
      return !autoRefresh ? of(0) : timer(0, 5000);
    })
  );

  postPatientToExclude$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientToExcludeActions.POST_PATIENT_TO_EXCLUDE),
      mergeMap((action) => {
        return this.patientToExcludeService
          .postPatientToExclude(action.patientToExclude)
          .pipe(
            map(() => {
              this.toastr.success('Patient to exclude posted successfully');
              return PatientToExcludeActions.PATIENT_TO_EXCLUDE_HAS_SUCCESSFULLY_POSTED(
                {
                  response: 'Patient to exclude posted successfully',
                  patientToExcludePosted: true,
                }
              );
            }),
            catchError((error) => {
              this.toastr.error(error.status, error.message);
              return of(
                PatientToExcludeActions.PATIENT_TO_EXCLUDE_POST_FAILURE({
                  error,
                })
              );
            })
          );
      })
    )
  );

  loadPatientToExclude$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PatientToExcludeActions.PATIENT_TO_EXCLUDE_HAS_SUCCESSFULLY_POSTED
      ),
      map((_action) => {
        return PatientToExcludeActions.LOAD_PATIENT_TO_EXCLUDE();
      })
    )
  );

  getPatientsToExclude$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientToExcludeActions.LOAD_PATIENT_TO_EXCLUDE),
      switchMap(() => {
        return this.timer$.pipe(
          switchMap(() =>
            this.patientToExcludeService.getStream().pipe(
              toArray(),
              map((patientsToExclude: IPatientToExclude[]) => {
                return PatientToExcludeActions.PATIENTS_TO_EXCLUDE_WERE_SUCCESSFULLY_LOADED(
                  { patientsToExclude }
                );
              }),
              catchError((error: string) => {
                this.toastr.error(error);
                return of(SharedState.DISABLE_AUTO_REFRESH());
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private patientsToExludeStore$: Store<PatientToExcludeState>,
    private patientToExcludeService: PatientToExcludeService
  ) {}
}
