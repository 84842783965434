import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BackendConfigurationService,
  HttpHelperService,
} from '@incepto/common-clients';
import { UserSessionRepositoryService } from '@incepto/core';
import { IComplaint, IComplaintTask } from '../model/complaint.model';
import { AbstractGatewayClient } from '@incepto-gateway/shared/common-gateway-client';
import { EBackendService } from '@incepto/common-models';

@Injectable({
  providedIn: 'root',
})
export class ComplaintsService extends AbstractGatewayClient<IComplaint> {
  constructor(
    backendConfigurationService: BackendConfigurationService,
    userSessionRepositoryService: UserSessionRepositoryService,
    private httpHelperService: HttpHelperService,
    http: HttpClient
  ) {
    super(
      backendConfigurationService,
      userSessionRepositoryService,
      http,
      'complaints'
    );
    this.buildClient();
  }

  postComplaint(complaint: IComplaint) {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    return this.http.post<IComplaintTask>(
      `${this.backendConfigurationService.getBackendServiceUrl(
        EBackendService.GATEWAY_SERVICE
      )}/complaints`,
      complaint,
      httpOptions
    );
  }
}
