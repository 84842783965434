import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromResourceMonitors from './state/resource-monitors.reducer';
import { CommonModule } from '@angular/common';
import { ResourceMonitorsFacade } from './state/resource-monitors.facade';
import { ResourceMonitorsService } from './client-resource-monitors.service';
import { resourceMonitorsEffects } from './state/resource-monitors.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromResourceMonitors.RESOURCE_MONITORS_FEATURE_KEY,
      fromResourceMonitors.reducer
    ),
    EffectsModule.forFeature([resourceMonitorsEffects]),
  ],
  providers: [ResourceMonitorsFacade, ResourceMonitorsService],
})
export class ResourceMonitorsDataAccessModule {}
