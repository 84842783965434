import { createAction, props } from '@ngrx/store';
import { IResourceMonitors } from '../resource-monitors.model';

export const init = createAction('[ResourceMonitors Page] Init');

export const loadResourceMonitorsSuccess = createAction(
  '[ResourceMonitors/API] Load ResourceMonitors Success',
  props<{ resourceMonitors: IResourceMonitors }>()
);

export const loadResourceMonitorsFailure = createAction(
  '[ResourceMonitors/API] Load ResourceMonitors Failure',
  props<{ error: string }>()
);
