import { Injectable } from '@angular/core';
import { STEP_LIST_VALUE } from '@incepto-gateway/shared/status-list';
import {
  IExamPerUseCaseAnalysis,
  IExamPerUseCaseDetailsView,
  IInputSeries,
  IInputStudies,
  IOutputAnalysisResults,
  IResultAET,
} from '../models/exam-per-use-case-details-view.model';

import {
  IExamPerUseCaseDetails,
  IExamPerUseCaseOutputs,
  IOutputAET,
  ISeriesDetails,
} from '../models/exam-per-use-case-details.model';
import {
  IAnalysisMonitoringInfo,
  IExamPerUseCase,
} from '../models/exam-per-use-case.model';

@Injectable({
  providedIn: 'root',
})
export class ExamPerUseCaseDetailsDataFormatterService {
  STEPS_LIST = STEP_LIST_VALUE;

  formatDataForDisplaying(data: {
    examPerUseCase: IExamPerUseCase;
    examPerUseCaseDetails: IExamPerUseCaseDetails;
    examPerUseCaseOutputs: IExamPerUseCaseOutputs[];
  }): IExamPerUseCaseDetailsView {
    if (
      'N/A' in data.examPerUseCaseDetails.analysisSeries &&
      data.examPerUseCase.workflowInfo.status == 'REJECTED'
    ) {
      data.examPerUseCase.analysesMonitoring.push({
        analysisId: 'N/A', //Create a virtual analysisId with the value 'N/A' matches the key 'N/A' in the map analysisSeries to display the rejected exam in the table
        open: false,
        workflowInfo: data.examPerUseCase.workflowInfo,
        triggeredAnalysisId: 'N/A',
        processingRequestId: 'N/A',
        createdDate: data.examPerUseCase.createdDate,
        resultsMonitoring: [],
        monitoring: null,
        processingTimes: null,
      });
    }
    return {
      examPerUseCase: data.examPerUseCase,
      analysesMonitoring: this.buildAnalysesMonitoring(
        data.examPerUseCase,
        data.examPerUseCaseDetails,
        data.examPerUseCaseOutputs
      ),
      examPerUseCaseDetails: data.examPerUseCaseDetails,
    };
  }

  private sortByCreatedDate<T extends { createdDate: string }>(
    datedObjectList: T[]
  ): T[] {
    return [...datedObjectList].sort(
      (datedObjectA, datedObjectB) =>
        new Date(datedObjectB.createdDate).getTime() -
        new Date(datedObjectA.createdDate).getTime()
    );
  }

  private buildAnalysesMonitoring(
    examPerUseCase: IExamPerUseCase,
    examPerUseCaseDetails: IExamPerUseCaseDetails,
    examPerUseCaseOutputs: IExamPerUseCaseOutputs[]
  ): IExamPerUseCaseAnalysis[] {
    const numberOfInstances =
      Object.values(examPerUseCaseDetails.numberOfInstancesPerSeries).reduce(
        (arr, value) => arr + value,
        0
      ) || 'N/A';
    return this.sortByCreatedDate(examPerUseCase.analysesMonitoring).map(
      (analysesMonitoring) => ({
        ...analysesMonitoring,
        errorMessage: analysesMonitoring.workflowInfo.errorMessage,
        step: this.STEPS_LIST[analysesMonitoring.workflowInfo.step],
        numberOfInstances:
          examPerUseCase.workflowInfo.status == 'REJECTED' &&
          numberOfInstances == 0
            ? 'N/A'
            : numberOfInstances,
        inputs: this.buildInputs(
          examPerUseCase,
          examPerUseCaseDetails,
          analysesMonitoring
        ),
        outputs: this.buildOutputs(
          examPerUseCaseOutputs,
          analysesMonitoring.analysisId
        ),
      })
    );
  }

  private buildInputs(
    examPerUseCase: IExamPerUseCase,
    examPerUseCaseDetails: IExamPerUseCaseDetails,
    analysesMonitoring: IAnalysisMonitoringInfo
  ): IInputStudies[] {
    return [
      this.buildCurrentStudies(
        examPerUseCase,
        analysesMonitoring,
        examPerUseCaseDetails
      ),
      ...this.buildPriorStudies(examPerUseCaseDetails, analysesMonitoring),
    ];
  }

  private buildCurrentStudies(
    examPerUseCase: IExamPerUseCase,
    analysesMonitoring: IAnalysisMonitoringInfo,
    examPerUseCaseDetails: IExamPerUseCaseDetails
  ): IInputStudies {
    return {
      studyInstanceUID: examPerUseCase.studyInstanceUID,
      accessionNumber: examPerUseCase.accessionNumber,
      studyDate: examPerUseCase.studyDate,
      studyTime: examPerUseCase.studyTime,
      studyDescription: examPerUseCase.studyDescription,
      numberOfInstances:
        examPerUseCaseDetails.seriesDetailList.reduce(
          (sum, series) =>
            sum +
            (examPerUseCaseDetails.numberOfInstancesPerSeries[
              series.seriesInstanceUID
            ] || 0),
          0
        ) || 'N/A',
      series: this.buildCurrentSeries(
        analysesMonitoring,
        examPerUseCaseDetails
      ),

      analysisIdAndstudyInstanceUID:
        analysesMonitoring.analysisId + '-' + examPerUseCase.studyInstanceUID,
    };
  }

  private buildCurrentSeries(
    analysesMonitoring: IAnalysisMonitoringInfo,
    examPerUseCaseDetails: IExamPerUseCaseDetails
  ): IInputSeries[] {
    const analysisSeriesIds: string[] = this.createAnalysisSeriesIds(
      examPerUseCaseDetails,
      analysesMonitoring
    );
    const currentAnalysisSeries: ISeriesDetails[] =
      examPerUseCaseDetails.seriesDetailList.filter(
        (seriesDetail) =>
          analysisSeriesIds.includes(seriesDetail.seriesInstanceUID) ||
          seriesDetail.state === 'REJECTED'
      );

    return currentAnalysisSeries.map((analysisSeries) => ({
      seriesInstanceUID: analysisSeries.seriesInstanceUID,
      seriesDescription: analysisSeries.dicomTags.SeriesDescription,
      modality: analysisSeries.dicomTags.Modality,
      status: analysisSeries.state,
      messages: analysisSeries.messages,
      numberOfInstancesPerSeries:
        examPerUseCaseDetails.numberOfInstancesPerSeries[
          analysisSeries.seriesInstanceUID
        ] == 0
          ? 'N/A'
          : examPerUseCaseDetails.numberOfInstancesPerSeries[
              analysisSeries.seriesInstanceUID
            ],
      seriesDate: analysisSeries.dicomTags.SeriesDate,
      seriesTime: analysisSeries.dicomTags.SeriesTime,
    }));
  }

  // TODO: DETAILED VIEW: Consider analyzePartialStudy or not?
  private buildPriorStudies(
    examPerUseCaseDetails: IExamPerUseCaseDetails,
    analysesMonitoring: IAnalysisMonitoringInfo
  ): IInputStudies[] {
    if (
      examPerUseCaseDetails.priorSeriesDetailList === undefined ||
      examPerUseCaseDetails.priorSeriesDetailList.length === 0
    ) {
      return [];
    }
    const studyInstancesUIDsWithUniqueness = [
      ...new Set(
        examPerUseCaseDetails.priorSeriesDetailList.map(
          (priorSeriesDetails) => priorSeriesDetails.studyInstanceUID
        )
      ),
    ];

    return studyInstancesUIDsWithUniqueness
      .map((studyInstancesUID) => {
        const analysisSeriesIds: string[] = this.createAnalysisSeriesIds(
          examPerUseCaseDetails,
          analysesMonitoring
        );
        const seriesDetails: ISeriesDetails[] =
          examPerUseCaseDetails.priorSeriesDetailList.filter(
            (priorSeries) =>
              priorSeries.studyInstanceUID === studyInstancesUID &&
              (analysisSeriesIds.includes(priorSeries.seriesInstanceUID) ||
                priorSeries.state === 'REJECTED')
          );
        return {
          analysisId: analysesMonitoring.analysisId,
          studyInstancesUID,
          seriesDetails,
        };
      })
      .filter(
        (studyWithSeriesDetails) =>
          studyWithSeriesDetails.seriesDetails.length !== 0
      )
      .map((filteredStudyDetails) => {
        const inputSeries: IInputSeries[] = this.buildPriorInputSeries(
          filteredStudyDetails.seriesDetails,
          examPerUseCaseDetails
        );

        return {
          studyInstanceUID: filteredStudyDetails.studyInstancesUID,
          accessionNumber:
            filteredStudyDetails.seriesDetails[0].accessionNumber,
          studyDate: filteredStudyDetails.seriesDetails[0].dicomTags.StudyDate,
          studyTime: filteredStudyDetails.seriesDetails[0].dicomTags.StudyTime,
          studyDescription:
            filteredStudyDetails.seriesDetails[0].dicomTags.StudyDescription,
          series: inputSeries,

          analysisIdAndstudyInstanceUID:
            analysesMonitoring.analysisId +
            '-' +
            filteredStudyDetails.studyInstancesUID,
          numberOfInstances:
            inputSeries
              .filter((series) => series.status === 'DONE')
              .reduce(
                (arr, value) => arr + Number(value.numberOfInstancesPerSeries),
                0
              ) || 'N/A',
        };
      });
  }

  private buildPriorInputSeries(
    seriesDetailsArray: ISeriesDetails[],
    examPerUseCaseDetails: IExamPerUseCaseDetails
  ): IInputSeries[] {
    return seriesDetailsArray.map((seriesDetails) => ({
      seriesInstanceUID: seriesDetails.seriesInstanceUID,
      seriesDescription: seriesDetails.dicomTags.SeriesDescription,
      modality: seriesDetails.dicomTags.Modality,
      status: seriesDetails.state,
      messages: seriesDetails.messages,
      numberOfInstancesPerSeries:
        examPerUseCaseDetails.numberOfInstancesPerSeries[
          seriesDetails.seriesInstanceUID
        ],
      seriesDate: seriesDetails.dicomTags.SeriesDate,
      seriesTime: seriesDetails.dicomTags.SeriesTime,
    }));
  }

  private createAnalysisSeriesIds(
    examPerUseCaseDetails: IExamPerUseCaseDetails,
    analysesMonitoring: IAnalysisMonitoringInfo
  ) {
    const analysisId =
      !analysesMonitoring.analysisId ||
      analysesMonitoring.analysisId.trim() === ''
        ? 'WAIT_FOR_TRIGGER'
        : analysesMonitoring.analysisId;
    return examPerUseCaseDetails.analysisSeries[analysisId] ?? [];
  }

  private buildOutputs(
    examPerUseCaseOutputs: IExamPerUseCaseOutputs[],
    analysisId: string
  ): IOutputAnalysisResults[] {
    return examPerUseCaseOutputs
      .filter(
        (examPerUseCaseOutput) => examPerUseCaseOutput.analysisId === analysisId
      )
      .map((examPerUseCaseOutput) => ({
        resultType: examPerUseCaseOutput.resultType,
        modality: examPerUseCaseOutput.modality,
        seriesDescription: examPerUseCaseOutput.seriesDescription,
        numberOfInstancesinSeries: examPerUseCaseOutput.numberOfInstances,
        server: examPerUseCaseOutput.server,
        state: examPerUseCaseOutput.state,
        errorMessage: examPerUseCaseOutput.errorMessage,
        outputAETs: this.buildResultAETs(examPerUseCaseOutput.outputAETs),
      }));
  }

  buildResultAETs(outputAETs: IOutputAET[]): IResultAET[] {
    return (
      outputAETs?.map((outputAET) => ({
        aet: outputAET.outputAET.remoteAet,
        status: outputAET.state,
        storeDateTime: outputAET.successDate,
        errorMessage: outputAET.errorMessage,
      })) ?? []
    );
  }
}
