import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as ResourceMonitorsActions from './resource-monitors.actions';
import { IResourceMonitors } from '../resource-monitors.model';

export const RESOURCE_MONITORS_FEATURE_KEY = 'resource-monitors';

export interface State extends EntityState<IResourceMonitors> {
  loaded: boolean; // has the ResourceMonitors been loaded
  error?: string | null; // last known error (if any)
}

export interface ResourceMonitorsPartialState {
  readonly [RESOURCE_MONITORS_FEATURE_KEY]: State;
}

// TODO: [CD-5392] should probably refactor, we are not using entities (only one object)
// TODO: [CD-5392] take inspiration from shared.reducer.ts
export const resourceMonitorsAdapter: EntityAdapter<IResourceMonitors> =
  createEntityAdapter<IResourceMonitors>({
    selectId: (e) => 'dummyId',
  });

export const initialState: State = resourceMonitorsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const resourceMonitorsReducer = createReducer(
  initialState,
  on(ResourceMonitorsActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    ResourceMonitorsActions.loadResourceMonitorsSuccess,
    (state, { resourceMonitors }) =>
      resourceMonitorsAdapter.setOne(resourceMonitors, {
        ...state,
        loaded: true,
      })
  ),

  on(
    ResourceMonitorsActions.loadResourceMonitorsFailure,
    (state, { error }) => ({ ...state, error })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return resourceMonitorsReducer(state, action);
}
