import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractReadOnlyClient,
  BackendConfigurationService,
} from '@incepto/common-clients';
import { EBackendService } from '@incepto/common-models';
import { UserSessionRepositoryService } from '@incepto/core';

@Injectable({
  providedIn: 'root',
})
export class AbstractGatewayClient<TObj> extends AbstractReadOnlyClient<TObj> {
  constructor(
    backendConfigurationService: BackendConfigurationService,
    userSessionRepositoryService: UserSessionRepositoryService,
    http: HttpClient,
    endpoint: string
  ) {
    super(
      backendConfigurationService,
      userSessionRepositoryService,
      http,
      EBackendService.GATEWAY_SERVICE,
      endpoint
    );
    this.backendConfigurationService.getBackendServiceUrl(
      EBackendService.GATEWAY_SERVICE
    );
  }
}
