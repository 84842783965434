import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IExamPerUseCase } from '../../models/exam-per-use-case.model';
import * as ExamsPerUseCaseActions from '../actions/exam-per-use-case.actions';

export const EXAMS_PER_USE_CASE_FEATURE_KEY = 'exams-per-use-case';

export type FilterRSQLOperator = '=rei=' | '=in=';

export type RSQLFilter =
  | RSQLFilterRegexQuery
  | RSQLFilterInQuery
  | RSQLFilterNinQuery
  | RSQLFilterNeqQuery
  | RSQLFilterGreaterOrEqualQuery
  | RSQLFilterLessOrEqualQuery;

export type RSQLFilterRegexQuery = {
  key: string;
  value: string;
  operator: '=rei=';
};

export type RSQLFilterInQuery = {
  key: string;
  value: string[];
  operator: '=in=';
};

type RSQLFilterNinQuery = {
  key: string;
  value: string[];
  operator: '=nin=';
};

type RSQLFilterNeqQuery = {
  key: string;
  value: string;
  operator: '!=';
};

type RSQLFilterGreaterOrEqualQuery = {
  key: string;
  value: string;
  operator: '=gte=';
};

type RSQLFilterLessOrEqualQuery = {
  key: string;
  value: string;
  operator: '=lte=';
};

export interface ExamPerUseCaseState extends EntityState<IExamPerUseCase> {
  loaded: boolean; // has the ExamPerUseCase list been loaded
  expandedRowExamUseCaseId: string | undefined;
  error?: string | null; // last known error (if any)
  page: number;
  enableNextPage: boolean;
  patientIDFilter: RSQLFilterRegexQuery;
  accessionNumberFilter: RSQLFilterRegexQuery;
  useCaseFilter: RSQLFilterInQuery;
  originFilter: RSQLFilterInQuery;
  studyDescriptionFilter: RSQLFilterRegexQuery;
  statusFilter: RSQLFilterInQuery;
  applicationFilter: RSQLFilterInQuery;
  stepFilter: RSQLFilterInQuery;
  typeFilter: RSQLFilterInQuery;
  studyStartDatetimeFilter: RSQLFilterGreaterOrEqualQuery;
  studyEndDatetimeFilter: RSQLFilterLessOrEqualQuery;
  creationStartDateFilter: RSQLFilterGreaterOrEqualQuery;
  creationEndDateFilter: RSQLFilterLessOrEqualQuery;
}

export interface ExamPerUseCasePartialState {
  readonly [EXAMS_PER_USE_CASE_FEATURE_KEY]: ExamPerUseCaseState;
}

export const examsPerUseCaseAdapter: EntityAdapter<IExamPerUseCase> =
  createEntityAdapter<IExamPerUseCase>({});

export const initialState: ExamPerUseCaseState =
  examsPerUseCaseAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    expandedRowExamUseCaseId: undefined,
    page: 0,
    enableNextPage: true,
    processingRequestIdFilter: {
      key: 'processingRequestId',
      value: '',
      operator: '=rei=',
    },
    patientIDFilter: { key: 'patientID', value: '', operator: '=rei=' },
    accessionNumberFilter: {
      key: 'accessionNumber',
      value: '',
      operator: '=rei=',
    },
    useCaseFilter: { key: 'useCase', value: [], operator: '=in=' },

    applicationFilter: { key: 'application', value: [], operator: '=in=' },
    // TODO : Update backend to get info available higher and to be able to make RSQL request (today 'state' goes nowhere)

    statusFilter: { key: 'workflowInfo.status', value: [], operator: '=in=' },
    stepFilter: { key: 'workflowInfo.step', value: [], operator: '=in=' },
    typeFilter: { key: 'type', value: [], operator: '=in=' },
    originFilter: { key: 'origin.remoteAet', value: [], operator: '=in=' },
    studyDescriptionFilter: {
      key: 'studyDescription',
      value: '',
      operator: '=rei=',
    },
    studyStartDatetimeFilter: {
      key: 'studyDate',
      value: '',
      operator: '=gte=',
    },
    studyEndDatetimeFilter: { key: 'studyDate', value: '', operator: '=lte=' },
    creationStartDateFilter: {
      key: 'createdDate',
      value: '',
      operator: '=gte=',
    },
    creationEndDateFilter: { key: 'createdDate', value: '', operator: '=lte=' },
  });

export const examsPerUseCaseReducer = createReducer(
  initialState,
  on(ExamsPerUseCaseActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),

  on(
    ExamsPerUseCaseActions.UPDATE_EXPANDED_ROW_EXAM_USE_CASE_ID,
    (state, { expandedRowExamUseCaseId }) => ({
      ...state,
      expandedRowExamUseCaseId,
    })
  ),

  on(
    ExamsPerUseCaseActions.loadExamsPerUseCaseSuccess,
    (state, { examsPerUseCase }) => {
      return examsPerUseCaseAdapter.setAll(examsPerUseCase, {
        ...state,
        loaded: true,
      });
    }
  ),
  on(ExamsPerUseCaseActions.selectExamPerUseCase, (state, { selectedId }) => ({
    ...state,
    selectedId,
  })),

  on(ExamsPerUseCaseActions.updatePageNumber, (state, { page }) => ({
    ...state,
    page,
  })),
  on(
    ExamsPerUseCaseActions.updateEnableNextPage,
    (state, { enableNextPage }) => ({
      ...state,
      enableNextPage,
    })
  ),
  on(
    ExamsPerUseCaseActions.updatePatientIDFilter,
    (state, { patientIDFilterValue }) => ({
      ...state,
      patientIDFilter: {
        ...state.patientIDFilter,
        value: patientIDFilterValue,
      },
    })
  ),
  on(ExamsPerUseCaseActions.updateOriginFilter, (state, { originToFilter }) => {
    let originFilterValue: string[] = [];
    if (state.originFilter.value.includes(originToFilter)) {
      originFilterValue = state.originFilter.value.filter(
        (state) => state !== originToFilter
      );
    } else if (
      !state.originFilter.value.includes(originToFilter) &&
      originToFilter !== 'all'
    ) {
      originFilterValue = [...state.originFilter.value, originToFilter];
    }
    return {
      ...state,
      originFilter: {
        ...state.originFilter,
        value: originFilterValue,
      },
    };
  }),
  on(
    ExamsPerUseCaseActions.updateAccessionNumberFilter,
    (state, { accessionNumberFilterValue }) => ({
      ...state,
      accessionNumberFilter: {
        ...state.accessionNumberFilter,
        value: accessionNumberFilterValue,
      },
    })
  ),
  on(
    ExamsPerUseCaseActions.updateUseCaseFilter,
    (state, { useCaseToFilter }) => {
      let useCaseFilterValue: string[] = [];
      if (state.useCaseFilter.value.includes(useCaseToFilter)) {
        useCaseFilterValue = state.useCaseFilter.value.filter(
          (state) => state !== useCaseToFilter
        );
      } else if (
        !state.useCaseFilter.value.includes(useCaseToFilter) &&
        useCaseToFilter !== 'all'
      ) {
        useCaseFilterValue = [...state.useCaseFilter.value, useCaseToFilter];
      }
      return {
        ...state,
        useCaseFilter: {
          ...state.useCaseFilter,
          value: useCaseFilterValue,
        },
      };
    }
  ),
  on(
    ExamsPerUseCaseActions.updateApplicationFilter,
    (state, { applicationToFilter }) => {
      let applicationFilterValue: string[] = [];
      if (state.applicationFilter.value.includes(applicationToFilter)) {
        applicationFilterValue = state.applicationFilter.value.filter(
          (state) => state !== applicationToFilter
        );
      } else if (
        !state.applicationFilter.value.includes(applicationToFilter) &&
        applicationToFilter !== 'all'
      ) {
        applicationFilterValue = [
          ...state.applicationFilter.value,
          applicationToFilter,
        ];
      }
      return {
        ...state,
        applicationFilter: {
          ...state.applicationFilter,
          value: applicationFilterValue,
        },
      };
    }
  ),
  on(ExamsPerUseCaseActions.updateStatusFilter, (state, { statusToFilter }) => {
    let statusFilterValue: string[] = [];
    if (state.statusFilter.value.includes(statusToFilter)) {
      statusFilterValue = state.statusFilter.value.filter(
        (state) => state !== statusToFilter
      );
    } else if (
      !state.statusFilter.value.includes(statusToFilter) &&
      statusToFilter !== 'all'
    ) {
      statusFilterValue = [...state.statusFilter.value, statusToFilter];
    }
    return {
      ...state,
      statusFilter: {
        ...state.statusFilter,
        value: statusFilterValue,
      },
    };
  }),
  on(ExamsPerUseCaseActions.updateStepFilter, (step, { stepToFilter }) => {
    let stepFilterValue: string[] = [];
    if (step.stepFilter.value.includes(stepToFilter)) {
      stepFilterValue = step.stepFilter.value.filter(
        (step) => step !== stepToFilter
      );
    } else if (
      !step.stepFilter.value.includes(stepToFilter) &&
      stepToFilter !== 'all'
    ) {
      stepFilterValue = [...step.stepFilter.value, stepToFilter];
    }
    return {
      ...step,
      stepFilter: {
        ...step.stepFilter,
        value: stepFilterValue,
      },
    };
  }),
  on(ExamsPerUseCaseActions.updateTypeFilter, (state, { typeToFilter }) => {
    let typeFilterValue: string[] = [];
    if (state.typeFilter.value.includes(typeToFilter)) {
      typeFilterValue = state.typeFilter.value.filter(
        (type) => type !== typeToFilter
      );
    } else if (
      !state.typeFilter.value.includes(typeToFilter) &&
      typeToFilter !== 'all'
    ) {
      typeFilterValue = [...state.typeFilter.value, typeToFilter];
    }
    return {
      ...state,
      typeFilter: {
        ...state.typeFilter,
        value: typeFilterValue,
      },
    };
  }),
  on(
    ExamsPerUseCaseActions.updateStudyDescriptionFilter,
    (state, { studyDescriptionFilterValue }) => ({
      ...state,
      studyDescriptionFilter: {
        ...state.studyDescriptionFilter,
        value: studyDescriptionFilterValue,
      },
    })
  ),
  on(
    ExamsPerUseCaseActions.updateStudyStartDatetimeFilter,
    (state, { studyStartDatetimeFilterValue }) => ({
      ...state,
      studyStartDatetimeFilter: {
        ...state.studyStartDatetimeFilter,
        value: studyStartDatetimeFilterValue,
      },
    })
  ),
  on(
    ExamsPerUseCaseActions.updateStudyEndDatetimeFilter,
    (state, { studyEndDatetimeFilterValue }) => ({
      ...state,
      studyEndDatetimeFilter: {
        ...state.studyEndDatetimeFilter,
        value: studyEndDatetimeFilterValue,
      },
    })
  ),
  on(
    ExamsPerUseCaseActions.updateCreationStartDateFilter,
    (state, { creationStartDateFilterValue }) => ({
      ...state,
      creationStartDateFilter: {
        ...state.creationStartDateFilter,
        value: creationStartDateFilterValue,
      },
    })
  ),
  on(
    ExamsPerUseCaseActions.updateCreationEndDateFilter,
    (state, { creationEndDateFilterValue }) => ({
      ...state,
      creationEndDateFilter: {
        ...state.creationEndDateFilter,
        value: creationEndDateFilterValue,
      },
    })
  )
);
